// @ts-strict-ignore
import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import { Text } from '../../components/text';
import { SubjectRowLabel, RowDetails } from '../../components/StyledComponents';
import { black, charcoal15, primary } from '../../utils/colors';
import { CustomTooltip as Tooltip } from '../../components/tooltip';
import { GetAModuleUsageResponse, GetModuleUsageResponse } from '../../../types/routes/module';
import { ModuleLibrary } from '../../redux/reducers/module';
import TaskHistogram from './TaskHistogram';
import { useAppSelector } from '../../redux';
import { sortBy } from 'lodash';

export const ModuleUsage: React.FC<{
  library: ModuleLibrary;
  moduleUsage: GetModuleUsageResponse;
  subjectId: string;
}> = ({ library, moduleUsage, subjectId }) => {
  const fetchingModuleUsage = useAppSelector((state) => state.adminAnalytics.fetchingModuleUsage);
  const hasModuleUsageError = useAppSelector((state) => state.adminAnalytics.hasModuleUsageError);
  const [showStartedPercents, setShowStartedPercents] = React.useState(false);
  const [showCompletedPercents, setShowCompletedPercents] = React.useState(false);
  const [hasStudents, setHasStudents] = React.useState(false);
  const [modules, setModules] = React.useState([]);

  // Use localStorage instead of REACT state mechanism
  const statePath = `admin-analytics/subject-state/${subjectId}`;
  const state = JSON.parse(window.localStorage.getItem(statePath)) || {
    open: false,
  };

  const clickHandler = () => {
    state.open = !state.open;
    window.localStorage.setItem(statePath, JSON.stringify(state));
  };

  // Number of students who have started any module
  const all = moduleUsage.crossModule.length > 0 ? moduleUsage.crossModule[0][0] : 0;

  const minTimeWithUnits: string = moduleUsage.minTime + ' minute' + (moduleUsage.minTime > 1 ? 's' : '');

  const headerStartedId = React.useId();
  const headerCompletedId = React.useId();
  const completionId = React.useId();

  const subject = library[subjectId]; //todo sort?
  const childList = Object.keys(subject.children);

  useEffect(() => {
    // place a module in the order dictated by the library.json file
    const sortByLibraryOrder = function (module: GetAModuleUsageResponse): number {
      const val = subject.modules.indexOf(module.id);
      return val;
    };
    // filter modules shown to the ones used, and sort them as explained above
    const modulesList = sortBy(
      moduleUsage.modules.filter((x) => subject.modules.includes(x.id)),
      sortByLibraryOrder,
    );
    setModules(modulesList);
  }, [moduleUsage, subject, fetchingModuleUsage]);

  useEffect(() => {
    if (!fetchingModuleUsage && Object.keys(moduleUsage.modules).length && !hasModuleUsageError) {
      setHasStudents(subject.modules.length > 0 && modules.length > 0 && moduleUsage.registeredStudents > 0);
    }
  }, [modules]);

  /*
   * For the Tooltip anchorSelector, we need to create a valid CSS selector
   * https://stackoverflow.com/questions/73894096
   */
  return (
    <RowDetails {...(state.open ? { open: true } : {})}>
      <SubjectRowLabel onClick={clickHandler}>{subject.name.toUpperCase()}</SubjectRowLabel>
      {/* {!hasStudents && modules.length > 0 && (
        <Text variant="p">'No student data available for this subject.'</Text>
      )} */}
      {hasStudents && (
        <ModuleUsageTable>
          <ModuleUsageTableHeader>
            <ModuleUsageTableHeaderRow>
              <ModuleUsageTableHeaderCell></ModuleUsageTableHeaderCell>

              <ModuleUsageTableHeaderCell id={headerStartedId}>
                <ModuleUsageHeaderText variant="p" onClick={() => setShowStartedPercents((prev) => !prev)}>
                  <span
                    title={
                      'Students that have started but not completed the module.  Started is > ' +
                      minTimeWithUnits +
                      ' time-on-task for any task.'
                    }
                  >
                    Students Started{showStartedPercents ? ' (%)' : ''}
                  </span>
                </ModuleUsageHeaderText>
                <Tooltip anchorSelect={`[id="${headerStartedId}"]`} place="top">
                  <Text variant="p">
                    Click to view as {!showCompletedPercents ? 'percent completed' : 'number of students'}
                  </Text>
                </Tooltip>
              </ModuleUsageTableHeaderCell>

              <ModuleUsageTableHeaderCell>
                <ModuleUsageHeaderText variant="p">
                  <span title={'Median time spent in module by students that have started the module'}>
                    Median Time (min.)
                    <br />
                    in Module
                  </span>
                </ModuleUsageHeaderText>
              </ModuleUsageTableHeaderCell>

              <ModuleUsageTableHeaderCell id={headerCompletedId}>
                <ModuleUsageHeaderText variant="p" onClick={() => setShowCompletedPercents((prev) => !prev)}>
                  <span title={'Students that have completed the entire module'}>
                    Students Completing Entire Module{showCompletedPercents ? ' (%)' : ''}
                  </span>
                </ModuleUsageHeaderText>
                <Tooltip anchorSelect={`[id="${headerCompletedId}"]`} place="top">
                  <Text variant="p">
                    Click to view as {!showStartedPercents ? 'percent of active students' : 'number of students'}
                  </Text>
                </Tooltip>
              </ModuleUsageTableHeaderCell>

              <ModuleUsageTableHeaderCell>
                <ModuleUsageHeaderText variant="p">
                  Median Completion
                  <br />
                  Time (min.)
                </ModuleUsageHeaderText>
              </ModuleUsageTableHeaderCell>
            </ModuleUsageTableHeaderRow>
          </ModuleUsageTableHeader>
          <ModuleUsageTableBody>
            {modules.map((module, index) => {
              const { id, name, completedTimeOnTask, startedTimeOnTask, finishedTasksCount } = module;
              const noData = 0 == finishedTasksCount.length;
              const enhancedCompletionId = completionId + String(id);
              return (
                <ModuleUsageTableRow key={index}>
                  <ModuleUsageTableCell>
                    <ModuleNameText variant="md">{name}</ModuleNameText>
                  </ModuleUsageTableCell>

                  <ModuleUsageTableCell>
                    <ModuleUsageDataText variant="md">
                      {hasStudents
                        ? showStartedPercents
                          ? all > 0
                            ? `${Math.round((100 * startedTimeOnTask.count) / all)}%`
                            : '-'
                          : `${startedTimeOnTask.count}`
                        : '-'}
                    </ModuleUsageDataText>
                  </ModuleUsageTableCell>

                  <ModuleUsageTableCell>
                    <ModuleUsageDataText variant="md">
                      {startedTimeOnTask.count > 0 ? Math.round(startedTimeOnTask.median / 60) : '-'}
                    </ModuleUsageDataText>
                  </ModuleUsageTableCell>

                  <ModuleUsageTableCell
                    id={enhancedCompletionId}
                    data-cy="admin-analytics-module-usage-started-time-on-task"
                  >
                    <ModuleUsageDataText variant="md">
                      {hasStudents
                        ? showCompletedPercents
                          ? startedTimeOnTask.count > 0
                            ? `${((100 * completedTimeOnTask.count) / startedTimeOnTask.count).toFixed(0)}%`
                            : '-'
                          : `${completedTimeOnTask.count}`
                        : '-'}
                    </ModuleUsageDataText>
                    {!noData && (
                      <ReStyledTooltip anchorSelect={`[id="${enhancedCompletionId}"]`} place="top">
                        {finishedTasksCount.map((finishedTasks, index) => (
                          <TaskHistogram
                            finishedTasks={finishedTasks}
                            moduleId={id}
                            title={finishedTasksCount.length > 1 ? finishedTasks.productName : null}
                            key={'finished-tasks-' + index}
                          />
                        ))}
                      </ReStyledTooltip>
                    )}
                  </ModuleUsageTableCell>
                  <ModuleUsageTableCell>
                    <ModuleUsageDataText variant="md">
                      {completedTimeOnTask.count > 0 ? Math.round(completedTimeOnTask.median / 60) : '-'}
                    </ModuleUsageDataText>
                  </ModuleUsageTableCell>
                </ModuleUsageTableRow>
              );
            })}
          </ModuleUsageTableBody>
        </ModuleUsageTable>
      )}
      {childList.length > 0 &&
        childList.map((childSubjectId: string) => (
          <ModuleUsage
            library={subject.children}
            moduleUsage={moduleUsage}
            subjectId={childSubjectId}
            key={childSubjectId}
          />
        ))}
    </RowDetails>
  );
};

const ModuleUsageTable = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
  marginBottom: '1rem',
});

const ModuleUsageTableHeader = styled('thead')({});

const ModuleUsageTableHeaderCell = styled('th')({
  padding: '0.5rem 1rem',
  textAlign: 'center',
  borderRight: `1px solid ${charcoal15}`,
  '&:last-child': {
    borderRight: 'none',
  },
});

const ModuleUsageTableHeaderRow = styled('tr')({
  borderBottom: `1px solid ${primary}`,
});

const ModuleUsageTableBody = styled('tbody')({});

const ModuleUsageTableRow = styled('tr')({
  borderBottom: `1px solid ${black}`,
  '&:last-child': {
    borderBottom: 'none',
  },
});

const ModuleUsageTableCell = styled('td')({
  padding: '0.4rem',
  borderRight: `1px solid ${charcoal15}`,
  '&:last-child': {
    borderRight: 'none',
  },
});

const ModuleUsageHeaderText = styled(Text)({
  color: black,
  fontWeight: 500,
  textAlign: 'center',
});

const ModuleNameText = styled(Text)({
  color: primary,
  fontWeight: 400,
  textAlign: 'left',
});

const ModuleUsageDataText = styled(Text)({
  color: black,
  fontWeight: 400,
  textAlign: 'center',
});

const ReStyledTooltip = styled(Tooltip)({
  opacity: 1,
});
