// @ts-strict-ignore
import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { pickBy, map, sortBy, round, Dictionary } from 'lodash';
import styled from '@emotion/styled';

import { useAppSelector } from '../../redux';
import { Text } from '../../components/text';
import { primary, gray } from '../../utils/colors';
import { ModuleEventWithUserClass, PublicUser } from '../../../types/models';
import { formatName, sortOrder } from '../../utils/user';
import { CustomTooltip as Tooltip } from '../../components/tooltip';
import { StyledTable } from '../../components/styles';
import { SubjectLeafProps } from '../../components/SubjectTree';
import { GetStudentUsageResponse, Part, PartType } from '../../../types/routes/module';
import { Student } from '../../../types/routes/class';
import {
  StudentInformationModalData,
  StudentInformationModal,
  defaultStudentInformationModalData,
} from '../../components/StudentInformationModal';

export interface LeafData {
  studentUsage: GetStudentUsageResponse;
  eventsByStudent: Dictionary<ModuleEventWithUserClass[]>;
}

export const ProgressTable = ({ subjectId, subjectModules, currentClass, leafData }: SubjectLeafProps<LeafData>) => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const debug = query.get('debug') ? true : false;
  const debugPrint = false;
  const studentUsage = leafData.studentUsage;
  const eventsByStudent = leafData.eventsByStudent;

  const classId = currentClass.id;
  const students: Student[] = currentClass.students;
  const fetchingStudentUsage = useAppSelector((state) => state.class.fetchingStudentUsage);
  const fetchingModuleEvents = useAppSelector((state) => state.module.fetchingModuleEvents);
  const modulesDefinition = useAppSelector((state) => state.module.definitions.modules);
  const navigate = useNavigate();

  const [stuSort, setStuSort] = useState<{ sortBy: string; order: boolean }>({ sortBy: 'student', order: true });
  const [sortFunction, setSortFunction] = useState(sortOrder);
  // use a single tooltip for all popups
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [tooltipAnchorId, setTooltipAnchorId] = useState('tooltip-anchor');
  const [tooltipStats, setTooltipStats] = useState({ taskCount: 0, timeOnPart: 0, startedTasks: 0, completedTasks: 0 });
  const [studentInformationModalData, setStudentInformationModalData] = useState<StudentInformationModalData>(
    defaultStudentInformationModalData,
  );

  useEffect(() => {
    const studentId = parseInt(query.get('student'));
    const moduleId = query.get('module');
    const modulePart = parseInt(query.get('part'));
    if (
      !fetchingStudentUsage &&
      studentId &&
      students.some((student) => student.id == studentId) &&
      moduleId &&
      modulePart &&
      subjectModules.includes(moduleId)
    ) {
      // if there are any started tasks, there should be events to process. This flag
      // signals to the modal to stay in a loading state.
      const expectEvents =
        moduleId in studentUsage.students[studentId].modules
          ? studentUsage.students[studentId].modules[moduleId].moduleParts[modulePart].startedTasks > 0
          : false;
      setStudentInformationModalData({
        open: true,
        student: students.find((s) => s.id == studentId),
        currentClass,
        studentEvents:
          studentId in eventsByStudent ? eventsByStudent[studentId].filter((e) => e.moduleId === moduleId) : [],
        moduleId,
        modulePart,
        expectEvents,
      });
    }
  }, [fetchingStudentUsage]);

  useEffect(() => {
    if (studentInformationModalData.open) {
      query.set('student', `${studentInformationModalData.student.id}`);
      query.set('module', `${studentInformationModalData.moduleId}`);
      query.set('part', `${studentInformationModalData.modulePart}`);
    } else {
      query.delete('student');
      query.delete('module');
      query.delete('part');
    }
    navigate(
      {
        pathname: window.location.pathname,
        search: query.toString(),
      },
      {
        replace: true,
      },
    );
  }, [studentInformationModalData]);

  const getModule = (moduleId: string) => modulesDefinition.find((module) => module.id == moduleId);

  const moduleParts: { [module: string]: Part[] } = {};
  for (const modId of subjectModules) {
    const module = getModule(modId);
    if (module && module.parts) {
      moduleParts[module.id] = module.parts;
    }
  }

  const moduleFilter = (_, key: string) =>
    modulesDefinition.some(
      (module) => module.id == key && module.subjectId == subjectId && subjectModules.includes(module.id),
    );

  const updateSortFunction = (sortData: string) => {
    if (sortData == 'student') {
      setSortFunction(sortOrder);

      if (stuSort.sortBy == 'student') {
        setStuSort({ sortBy: 'student', order: !stuSort.order });
      } else {
        setStuSort({ sortBy: 'student', order: true });
      }
    } else {
      setSortFunction([(student: PublicUser) => progress[student.id][sortData], sortOrder[0], sortOrder[1]]);
      if (stuSort.sortBy == sortData) {
        setStuSort({ sortBy: sortData, order: !stuSort.order });
      } else {
        setStuSort({ sortBy: sortData, order: true });
      }
    }
  };

  const progress = {};
  for (const student of students) {
    progress[student.id] = {};

    if (studentUsage.students[student.id]) {
      const selectedSubjectModules = pickBy(studentUsage.students[student.id].modules, moduleFilter);
      map(selectedSubjectModules, (usage, module) => {
        progress[student.id][module] = 0;
        map(usage.moduleParts, (stats) => {
          progress[student.id][module] -= stats.completedTasks / stats.taskCount;
        });
      });
    }
  }

  const updateTooltip = (anchorId, stats) => {
    setTooltipAnchorId(anchorId);
    setTooltipStats(stats);
    setTooltipIsOpen(true);
  };

  const closeTooltip = () => {
    setTooltipIsOpen(false);
    setTooltipAnchorId('tooltip-anchor');
  };

  if (debugPrint)
    console.log(
      `**** ProgressTable loading for class ${classId}, ${subjectId} for modules ${subjectModules.join(', ')} and ${
        students.length
      } students`,
    );

  return (
    <>
      <TableContainer>
        {debug && (
          <Text variant="nav">
            moduleParts: {map(moduleParts, (_parts, id) => id).join(', ')} subjectModules: {subjectModules.join(', ')}
          </Text>
        )}
        <Tooltip isOpen={tooltipIsOpen} delayShow={0} anchorSelect={'#' + tooltipAnchorId}>
          <Text variant="p">
            {tooltipStats.completedTasks}/{tooltipStats.taskCount} tasks completed
          </Text>
          <Text variant="p">{round(tooltipStats.timeOnPart / 60)} min spent</Text>
        </Tooltip>
        <Tooltip anchorSelect=".supplement-header">
          <Text variant="nav">Supplemental activity</Text>
        </Tooltip>
        <StyledTable className="progressTable">
          <thead>
            <tr>
              <th>
                <HeadText>
                  <StudentText variant="nav"> Student Name</StudentText>
                  <Arrow
                    variant="md"
                    color={stuSort.sortBy == 'student' ? primary : gray}
                    onClick={() => updateSortFunction('student')}
                  >
                    {stuSort.sortBy == 'student' ? (stuSort.order ? '↓' : '↑') : '↕'}
                  </Arrow>
                </HeadText>
              </th>
              {map(
                moduleParts,
                (parts: Part[], moduleId) =>
                  subjectId && (
                    <th key={moduleId}>
                      <HeadText>
                        <div>
                          <ModuleText variant="nav">{getModule(moduleId)?.name || 'missing name'}</ModuleText>
                          <Parts>
                            {parts.map((part: Part) => (
                              <PartContainer
                                key={part.modulePart}
                                className={part.type === PartType.supplement ? 'supplement-header' : null}
                              >
                                {part ? (
                                  <Text key={part.modulePart} variant="nav">
                                    Part {String(part.modulePart) + (part.type === PartType.supplement ? '*' : '')}
                                  </Text>
                                ) : null}
                              </PartContainer>
                            ))}
                          </Parts>
                        </div>
                        <Arrow
                          variant="md"
                          color={stuSort.sortBy == moduleId ? primary : gray}
                          onClick={() => updateSortFunction(moduleId)}
                        >
                          {stuSort.sortBy == moduleId ? (stuSort.order ? '↓' : '↑') : '↕'}
                        </Arrow>
                      </HeadText>
                    </th>
                  ),
              )}
            </tr>
          </thead>
          <tbody>
            {students?.length &&
              !fetchingStudentUsage &&
              (stuSort.order
                ? sortBy<Student>(students, sortFunction)
                : sortBy<Student>(students, sortFunction).reverse()
              ).map((student) => (
                <tr key={student.id}>
                  <td>
                    <Text variant="nav">{student && formatName(student)}</Text>
                    {debug && <Text variant="nav">{student.id}</Text>}
                  </td>
                  {studentUsage.students[student.id] &&
                    map(subjectModules, (moduleId) => (
                      <td key={moduleId}>
                        <Parts>
                          {moduleId in studentUsage.students[student.id].modules
                            ? map(studentUsage.students[student.id].modules[moduleId].moduleParts, (stats, part) => {
                                const id = moduleId + '-' + part + '-' + String(student.id);
                                const studentEvents =
                                  !fetchingModuleEvents && student.id in eventsByStudent
                                    ? eventsByStudent[student.id].filter((e) => e.moduleId === moduleId)
                                    : [];
                                return (
                                  <div
                                    key={part}
                                    onClick={() =>
                                      setStudentInformationModalData({
                                        open: true,
                                        student,
                                        currentClass,
                                        studentEvents,
                                        moduleId,
                                        modulePart: Number(part),
                                        expectEvents: stats.startedTasks > 0,
                                      })
                                    }
                                  >
                                    <PartContainer key={part} onMouseLeave={() => closeTooltip()}>
                                      <Pie
                                        completed={stats.completedTasks}
                                        total={stats.taskCount}
                                        id={id}
                                        onMouseEnter={() => updateTooltip(id, stats)}
                                      />
                                    </PartContainer>
                                    {debug && (
                                      <Text variant="nav">
                                        {moduleId} {part}
                                      </Text>
                                    )}
                                  </div>
                                );
                              })
                            : null}
                        </Parts>
                      </td>
                    ))}
                </tr>
              ))}
          </tbody>
        </StyledTable>
      </TableContainer>
      {studentInformationModalData.open && (
        <StudentInformationModal
          studentInformationModalData={studentInformationModalData}
          setStudentInformationModalData={setStudentInformationModalData}
        />
      )}
    </>
  );
};

const TableContainer = styled.div({
  overflow: 'scroll',
  display: 'flex',
  flexWrap: 'wrap',
  padding: '1rem 0',

  table: {
    '&.progressTable': {
      tableLayout: 'fixed',
      margin: 0,
      thead: {
        th: {
          background: 'white',
          position: 'sticky',
          top: 0,
          zIndex: 3,
          paddingRight: '0.25rem',
          paddingTop: '.5rem',
        },
        'th:first-of-type': {
          left: '0rem',
          zIndex: 4,
          borderRight: `1px solid ${gray}`,
        },
      },
      'tbody > tr > td:first-of-type': {
        position: 'sticky',
        background: 'white',
        left: '0rem',
        zIndex: 2,
        borderRight: `1px solid ${gray}`,
      },
    },
  },
});

const HeadText = styled.div({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'end',
});

const StudentText = styled(Text)({
  whiteSpace: 'nowrap',
});

const Arrow = styled(Text)({
  fontSize: '1.5rem',
  marginLeft: '0.5rem',
  marginRight: '0rem',
  cursor: 'pointer',
});

const Parts = styled.div({
  height: '1.5rem',
  display: 'flex',
  justifyContent: 'center',
});

const ModuleText = styled(Text)({
  color: primary,
  textTransform: 'capitalize',
});

const PartContainer = styled.div({
  width: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
});

const Pie = styled.div<{ completed: number; total: number }>(({ completed, total }) => {
  let fraction = 0;
  if (completed == 0) {
    fraction = 0;
  } else if (4 * completed <= total) {
    fraction = 0.25;
  } else if (2 * completed <= total) {
    fraction = 0.5;
  } else if (completed + 2 <= total) {
    fraction = 0.75;
  } else {
    fraction = 1;
  }

  return {
    height: '2rem',
    width: '2rem',
    borderRadius: '50%',
    border: `0.1rem solid ${primary}`,
    background: `conic-gradient(${primary} calc(${fraction}*100%), #0000 0)`,
  };
});
