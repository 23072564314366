import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux';
import { ProgressTable, LeafData } from './progressTable';
import { getStudentUsage } from '../../redux/actions/class';
import { SubjectTree } from '../../components/SubjectTree';
import { Dictionary } from 'lodash';
import { Class as ClassWithStudents } from '../../../types/routes/class';
import { StatsWrapperClassroom } from '../../components/StatsWrapperClassroom';
import { ModuleEventWithUserClass } from '../../../types/models';
import Progress from '../../assets/icons/progress.svg';
import { Spinner } from '../../components/Spinner';

interface Props {
  currentClass: ClassWithStudents;
  eventsByStudent: Dictionary<ModuleEventWithUserClass[]>;
}

export const ClassProgressPage: React.FC = () => {
  const params = useParams();
  return (
    <StatsWrapperClassroom
      match={params}
      ChildComponent={ClassProgressChild}
      pageTitle="Class Progress Report"
      classOnlyData={true}
      Icon={Progress}
    />
  );
};

const ClassProgressChild = ({ currentClass, eventsByStudent }: Props) => {
  const debugPrint = false;
  const dispatch = useAppDispatch();
  const modules = useAppSelector((state) => state.module.definitions);
  const studentUsage = useAppSelector((state) => state.class.studentUsage);
  const fetchingStudentUsage = useAppSelector((state) => state.class.fetchingStudentUsage);

  useEffect(() => {
    dispatch(getStudentUsage(currentClass.id));
  }, [currentClass]);

  const leafDataModuleList = new Set<string>();
  for (const s of Object.values(studentUsage.students)) {
    for (const moduleId of Object.keys(s.modules)) {
      leafDataModuleList.add(moduleId);
    }
  }

  if (debugPrint) console.log(`**** leafDataModuleList ${Array.from(leafDataModuleList).join(', ')}`);

  if (!currentClass || fetchingStudentUsage || Object.keys(modules).length == 0) {
    return <Spinner label="Fetching data ..." center />;
  } else {
    return (
      <SubjectTree<LeafData>
        leafData={{ studentUsage, eventsByStudent }}
        leafDataModuleList={leafDataModuleList}
        LeafComponent={ProgressTable}
      />
    );
  }
};
