import React, { useEffect, useState } from 'react';
import { map, pick } from 'lodash';
import styled from '@emotion/styled';

import { Button } from '../components/button';
import Settings from '../assets/icons/settings.svg';
import { PageContainer } from '../components/pageContainer';
import { Header } from '../components/header';
import { useAppSelector, useAppDispatch } from '../redux';

import { primary, grayBackground, white } from '../utils/colors';
import { Alert } from '../components/Alert';
import { LeftNav } from '../components/leftNav';
import { Text } from '../components/text';
import { ActionBar } from '../components/styles';
import {
  getLtiPlatforms as getLtiPlatformsAction,
  registerLtiPlatform as registerLtiPlatformAction,
  updateLtiPlatform as updateLtiPlatformAction,
} from '../redux/actions/admin';
import { RegisterLtiPlatformRequestRecord } from '../../types/routes/admin';

export const LtiPlatform: React.FC = () => {
  const [platform, setPlatform] = useState('');
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getLtiPlatformsAction());
  }, []);
  const platforms = useAppSelector((state) => state.admin.ltiPlatforms);
  const register = async (): Promise<void> => {
    setError('');
    try {
      const platformObject = RegisterLtiPlatformRequestRecord.check(JSON.parse(platform));
      dispatch(registerLtiPlatformAction(platformObject));
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  };
  const onRegisterChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setPlatform(event.target.value);
  };
  const onActiveChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    console.log(event.target);
    dispatch(updateLtiPlatformAction({ id: event.target.name, active: event.target.checked }));
  };
  return (
    <PageContainer header={<Header />} leftNav={<LeftNav />}>
      <ActionBar>
        <Text variant="lg2" color={primary}>
          <StyledSettings />
          LTI Platforms
        </Text>
      </ActionBar>
      {map(platforms, (platform) => {
        const inputs = ['name', 'url', 'clientId', 'authenticationEndpoint', 'accesstokenEndpoint', 'authConfig'];
        const pp = pick(platform, inputs);
        return (
          <StyledPlatform key={platform.id} active={platform.active}>
            <label>
              Active
              <input name={platform.id} type="checkbox" checked={platform.active} onChange={onActiveChange} />
            </label>
            <pre>{JSON.stringify(pp, null, 2)}</pre>
          </StyledPlatform>
        );
      })}
      <StyledForm id="signup" onSubmit={register}>
        {error && <Alert severity="error" title={error} />}
        <textarea onChange={onRegisterChange} rows={10} cols={80} />
        <StyledButton onClick={register}>Register</StyledButton>
      </StyledForm>
    </PageContainer>
  );
};

const StyledForm = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const StyledSettings = styled(Settings)({
  height: '100%',
  marginRight: '1rem',
});

const StyledPlatform = styled.div<{ active: boolean }>(
  {
    marginBottom: '1rem',
  },
  ({ active }) => ({
    backgroundColor: active ? white : grayBackground,
  }),
);

const StyledButton = styled(Button)({
  width: '100%',
});
