import React, { useEffect, useState } from 'react';
import { isNil } from 'lodash';
import styled from '@emotion/styled';

import { Text } from '../../../components/text';
import { CustomTooltip as Tooltip } from '../../../components/tooltip';
import { charcoal15, primary, white, black, darkgray30 } from '../../../utils/colors';
import Check from '../../../assets/icons/check.svg';
import { formatName } from '../../../utils/user';
import { UnreadMessageNotification } from './unreadMessageNotification';
import { StudentOverrides, StudentStatus, UserWithClassId } from '../types';
import msgaudio from '../../../assets/sounds/message.wav';
import emaudio from '../../../assets/sounds/emergency.wav';
import LanguageIcon from '../../../assets/icons/language.svg';
import LaunchIcon from '../../../assets/icons/launch.svg';
import PauseAtTaskIcon from '../../../assets/icons/pause-at-task.svg';
import { getFirstTaskFinished, getLastTaskFinished } from './util';
import DisabledIcon from '../../../assets/icons/disabled.svg';
import BatteryIcon from '../../../assets/icons/battery.svg';
import { getEnabledFeaturesForStudent } from '../event-util';
import { useAppSelector } from '../../../redux';

interface Props {
  student: UserWithClassId;
  studentStatus: StudentStatus;
  selected: boolean;
  onClick: () => void;
}

enum Battery {
  left = 'left',
  right = 'right',
  headset = 'headset',
}

const batteryName = {
  [Battery.left]: 'Left controller',
  [Battery.right]: 'Right controller',
  [Battery.headset]: 'Headset',
};

interface BatteryInfo {
  level?: number;
  device: Battery;
}

const MESSAGE_NOTIF_KEY = 'messageNotif';

// Component to display the battery info
const BatteryIndicator = ({ batteryInfo, student }: { batteryInfo: BatteryInfo; student: UserWithClassId }) => {
  const color = !isNil(batteryInfo.level) && batteryInfo.level < 0.2 && batteryInfo.level >= 0 ? primary : 'black';
  const deviceName = batteryName[batteryInfo.device];
  const label = `battery-${batteryInfo.device}-${student.id}`;
  const percent = batteryInfo.level ? batteryInfo.level * 100 : 0;
  return (
    <>
      <BatteryIconAndText data-cy={label} id={label} color={color}>
        <StyledBatteryMeter>
          <StyledBatteryIcon />
          <StyledBatteryFill color={color} level={batteryInfo.level}>
            {(isNil(batteryInfo.level) || batteryInfo.level < 0) && `?`}
          </StyledBatteryFill>
        </StyledBatteryMeter>
      </BatteryIconAndText>
      <Tooltip anchorSelect={`#${label}`}>
        <Text center variant="nav">
          {!isNil(batteryInfo.level) && batteryInfo.level >= 0
            ? `${deviceName}: ${`${percent.toFixed(0)}%`}`
            : `${deviceName}: no battery info.`}
        </Text>
      </Tooltip>
    </>
  );
};

export const finishedPart = (status: StudentStatus): boolean =>
  status.part?.tasks.every((task) => status.finishedTaskSet.has(task.id)) ?? false;

export const Student = ({ student, selected, studentStatus, ...rest }: Props) => {
  const query = new URLSearchParams(window.location.search);
  const debug = query.get('debug') ? true : false;

  const [beeped, setBeeped] = useState(new Set(studentStatus.unreadMessages.map((message) => message.id)));
  const [hasSettingsOverride, setHasSettingsOverride] = useState(false);
  const [hasLaunchOverride, setHasLaunchOverride] = useState(false);
  const [hasPauseOverride, setHasPauseOverride] = useState(false);

  const moduleVersionDefinitions = useAppSelector((state) => state.module.versionDefinitions);
  const moduleDefinitions = useAppSelector((state) => state.module.definitions);
  const studentFeatures = getEnabledFeaturesForStudent(studentStatus, moduleVersionDefinitions, moduleDefinitions);

  const headsetBatteryInfo: BatteryInfo = { level: studentStatus.headsetBatteryLevel, device: Battery.headset };
  const leftBatteryInfo: BatteryInfo = { level: studentStatus.leftControllerBatteryLevel, device: Battery.left };
  const rightBatteryInfo: BatteryInfo = { level: studentStatus.rightControllerBatteryLevel, device: Battery.right };
  const showBatteryLevels =
    !isNil(headsetBatteryInfo.level) || !isNil(leftBatteryInfo.level) || !isNil(rightBatteryInfo.level);

  const activeTaskColor =
    studentStatus &&
    studentStatus.activeTaskIndex > -1 &&
    studentStatus.part?.tasks[studentStatus.activeTaskIndex] &&
    !studentStatus.finishedTaskSet.has(studentStatus.part.tasks[studentStatus.activeTaskIndex].id)
      ? primary
      : 'black';

  const getAudioNotif = () => {
    return window.localStorage.getItem(MESSAGE_NOTIF_KEY) === 'true' ? true : false;
  };

  useEffect(() => {
    // refresh student overrides
    setHasSettingsOverride(studentStatus.overrides.has(StudentOverrides.settings));
    setHasLaunchOverride(studentStatus.overrides.has(StudentOverrides.launch));
    setHasPauseOverride(studentStatus.overrides.has(StudentOverrides.pause));

    // handle audio notifications
    if (!getAudioNotif()) {
      return;
    }
    let newMessage = false;
    let emergency = false;
    studentStatus.unreadMessages.forEach((message) => {
      if (!beeped.has(message.id)) {
        newMessage = true;
        beeped.add(message.id);
        /*
         * Work-around for
         * https://www.notion.so/prismsvr/29294fbcb4cd47f0985472600c43f866
         */
        if (message.properties.message.match(/emergency/i)) {
          emergency = true;
        }
      }
    });
    if (newMessage) {
      const sound = new Audio(emergency ? emaudio : msgaudio);
      sound.play();
      setBeeped(beeped);
    }
  }, [studentStatus]);

  const getStudentProgress = (status: StudentStatus): React.ReactNode => {
    if (finishedPart(status)) return <Check color={'primary'} />;
    return (
      <Text variant="nav" color={activeTaskColor}>
        {status.activeTaskLabel}
      </Text>
    );
  };

  return (
    <Container
      active={selected}
      status={studentStatus}
      debug={debug}
      {...rest}
      id={`student-name-${student.id}`}
      data-cy={`liveClassroom-studentList-student-${student.id}`}
    >
      {studentStatus.unreadMessages.length > 0 && (
        <UnreadMessageNotification student={student} studentStatus={studentStatus} />
      )}
      <LeftRight>
        <Text variant="md">
          {formatName(student)}
          {debug && ' ' + student.id}
        </Text>
        <Text variant="nav" color={primary} className={studentStatus.displayClass}>
          {!studentStatus.idle && studentStatus.displayName}
        </Text>
        {debug && (
          <Text variant="nav">
            mess={studentStatus.unreadMessages.length} idle={studentStatus.idle ? '1' : '0'} st=
            {studentStatus.started ? '1' : '0'} fin={studentStatus.finishedTaskSet.size} overrides=
            {Array.from(studentStatus.overrides).join(', ')}
          </Text>
        )}
      </LeftRight>
      <Right>
        <>
          {(!!(studentStatus.overrides.size > 0) || !studentStatus.idle) && (
            <>
              {!!(studentStatus.overrides.size > 0) && (
                <Overrides>
                  <Left>
                    {hasSettingsOverride && <StyledLanguageIcon />}
                    {hasLaunchOverride && <StyledLaunchIcon />}
                    {hasPauseOverride && <StyledPauseAtTaskIcon />}
                  </Left>
                </Overrides>
              )}
              {!studentStatus.idle && showBatteryLevels && (
                <Overrides style={{ top: debug ? '4.4rem' : '2.9rem' }}>
                  <Left>
                    <StyledBatteryIconGroup>
                      <StyledBatteryIndicator batteryInfo={leftBatteryInfo} student={student} />
                      <StyledBatteryIndicator batteryInfo={headsetBatteryInfo} student={student} />
                      <StyledBatteryIndicator batteryInfo={rightBatteryInfo} student={student} />
                    </StyledBatteryIconGroup>
                  </Left>
                </Overrides>
              )}
            </>
          )}
          {!studentFeatures.length && (
            <Overrides>
              <Right>
                <StyledDisabledIcon id={'disabled-icon'} />
                <Tooltip anchorSelect={`#disabled-icon`} style={{ maxWidth: 300 }}>
                  <Text variant="nav">
                    This student is using a version of the VR App that does not support this feature.
                  </Text>
                </Tooltip>
              </Right>
            </Overrides>
          )}
          {!studentStatus.idle && (
            <LeftRight>
              <BackgroundProgressPie
                activeTaskColor={activeTaskColor}
                firstTaskStarted={getFirstTaskFinished(studentStatus)}
                lastTaskStarted={getLastTaskFinished(studentStatus)}
                totalTasks={studentStatus.part?.tasks.length ?? 0}
              >
                <StudentProgress id={`student-score-${student.id}`}>
                  {getStudentProgress(studentStatus)}
                </StudentProgress>
              </BackgroundProgressPie>
              <Tooltip anchorSelect={`#student-score-${student.id}`} place="left">
                <Text variant="nav">{finishedPart(studentStatus) ? 'Complete' : 'Current Task'}</Text>
              </Tooltip>
            </LeftRight>
          )}
        </>
      </Right>
    </Container>
  );
};

const dynamicStyle = (active: boolean, status: StudentStatus, debug: boolean): any => {
  const deg = active ? '90deg' : '90deg';
  const fade = status.idle ? '100%' : '500%';
  const statusColor = status.idle ? (active ? darkgray30 : charcoal15) : active ? primary : white;
  const backgroundImageStyle = `linear-gradient(${deg}, ${white} 0%, ${statusColor} ${fade})`;
  const borderStyle = active ? `2px solid ${primary}` : `2px solid ${black}`;

  return {
    backgroundImage: backgroundImageStyle,
    border: borderStyle,
    marginBottom: '.5rem',
    height: debug ? '4.5rem' : '3rem',
  };
};

const Container = styled.div<{ active: boolean; status: StudentStatus; debug: boolean }>(
  {
    padding: '0.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: `0 3px 5px ${charcoal15}`,
    position: 'relative',
    cursor: 'pointer',
    transition: 'background-image 1s',
    borderRadius: '1.5rem',
    margin: '0.25rem',
  },
  ({ active, status, debug }) => dynamicStyle(active, status, debug),
);

const Overrides = styled.div({
  padding: '0rem 0.25rem',
  border: `2px solid ${black}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  borderRadius: '1.5rem',
  zIndex: 99,
  position: 'absolute',
  top: '-10px',
  background: charcoal15,
});

const _iconStyle = {
  width: '0.9rem',
  height: '0.9rem',
  margin: '0.1rem',
  color: primary,
};

const StyledLanguageIcon = styled(LanguageIcon)(_iconStyle);
const StyledLaunchIcon = styled(LaunchIcon)(_iconStyle);
const StyledPauseAtTaskIcon = styled(PauseAtTaskIcon)(_iconStyle);
const StyledDisabledIcon = styled(DisabledIcon)(_iconStyle);

const LeftRight = styled.div({
  display: 'flex',
  alignItems: 'left',
  flexDirection: 'column',
});

const StudentProgress = styled.div({
  borderRadius: '100%',
  height: '1.6rem',
  width: '1.6rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0.2rem',
  backgroundColor: white,
  fontStyle: 'bold',
});

const BackgroundProgressPie = styled.div<{
  activeTaskColor: string;
  firstTaskStarted: number;
  lastTaskStarted: number;
  totalTasks: number;
}>(({ activeTaskColor, firstTaskStarted, lastTaskStarted, totalTasks }) => {
  const fractionSkipped = firstTaskStarted > 0 ? (firstTaskStarted - 1) / totalTasks : 0;
  let fraction = 0;
  if (lastTaskStarted <= 0) {
    fraction = 0;
  } else if (lastTaskStarted < totalTasks) {
    fraction = (lastTaskStarted - firstTaskStarted + 1) / totalTasks;
  } else {
    fraction = 1 - fractionSkipped;
  }

  return {
    height: '2rem',
    width: '2rem',
    borderRadius: '50%',
    border: `none`,
    background: `conic-gradient(from ${fractionSkipped}turn, ${activeTaskColor} calc(${fraction}*100%), ${charcoal15} 0)`,
  };
});

const StyledBatteryMeter = styled.div({
  position: 'relative',
  paddingLeft: '0.2rem',
});
const StyledBatteryIcon = styled(BatteryIcon)({
  position: 'absolute',
  top: '0',
  width: '1rem',
  height: '1.2rem',
});
const StyledBatteryFill = styled.div<{ color: string; level?: number }>(({ color, level }) => {
  if (!isNil(level) && level >= 0) {
    const heightRem = !isNil(level) ? level * 0.57 : 0;
    const height = `${heightRem}rem`;
    const top = `${0.9 - heightRem}rem`;
    return {
      position: 'absolute',
      background: color,
      height,
      width: '0.35rem',
      top,
      left: '0.36rem',
      color: 'inherit',
    };
  } else {
    return {
      position: 'absolute',
      width: '0.35rem',
      top: '0.3rem',
      left: '0.37rem',
      color: 'inherit',
      textAlign: 'center',
      fontSize: '0.6rem',
    };
  }
});

const StyledBatteryIndicator = styled(BatteryIndicator)({
  width: '1rem',
  height: '1rem',
  margin: '0.1rem',
});

const StyledBatteryIconGroup = styled.div({
  padding: '0.2rem 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const BatteryIconAndText = styled.div<{
  color: string;
}>(({ color }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    height: '1rem',
    width: '1rem',
    color,
  };
});

const Left = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
});

const Right = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
});
