// @ts-strict-ignore
import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { uniqBy } from 'lodash';
import { PageContainer } from './pageContainer';
import { Header } from './header';
import { ActionBar } from './styles';
import { LeftNav } from './leftNav';
import { useAppSelector, useAppDispatch } from '../redux';
import { eventSelector, getEvents, getVersionDefinitions } from '../redux/actions/module';
import { get as getClass } from '../redux/actions/class';
import { UserWithClassId } from '../pages/liveClassroom/types';
import { Alert, ExtraAlertText } from './Alert';
import { Button } from './button';
import { ClassroomPageTitle } from './ClassroomPageTitle';
import Target from '../assets/icons/target.svg';
import { IconPrimary } from '../pages/liveClassroom/ClassroomButtons';
import { Text } from './text';

import LowLatencyIcon from '../assets/icons/high-latency.svg';
import { Tooltip } from 'react-tooltip';
import { Spinner } from './Spinner';

// Interval to wait before polling the server for more events.
// Time in milliseconds
// This will make the Cypress tests perform better
const POLLING = process.env.NODE_ENV == 'test' ? 0 : 5000;

export const StatsWrapperClassroom = ({ match, ChildComponent, pageTitle, classOnlyData = false, Icon = Target }) => {
  const debugPrint = false;
  const pp = false; // Debug event fetching
  const dispatch = useAppDispatch();

  const classId = parseInt(match.classId);
  const query = new URLSearchParams(window.location.search);
  const before: number | null = query.get('before') ? parseInt(query.get('before')) : null;

  const fetchingClass = useAppSelector((state) => state.class.fetchingClass);
  const currentClass = useAppSelector((state) => state.class.classes.find((c) => c.id === classId));
  const classError = useAppSelector((state) => state.class.hasError);
  const errorMessage = useAppSelector((state) => state.class.errorMessage);
  if (debugPrint) {
    console.log(`*** currentClass ${classId} state: ${currentClass?.id}, ${currentClass?.classroomId}`);
  }
  const allClasses = useAppSelector((state) => state.class.classes);
  const classroomClasses = currentClass?.classroomId
    ? allClasses.filter((c) => c.classroomId == currentClass.classroomId)
    : currentClass
    ? [currentClass]
    : [];
  // Distinct students in the classroom
  const students: UserWithClassId[] = uniqBy(
    classroomClasses.reduce(
      (students, classroomClass) =>
        students.concat(
          classroomClass.students.map((student) => {
            return { ...student, classId: classroomClass.id };
          }),
        ),
      [],
    ),
    'id',
  );

  if (debugPrint) {
    console.log(
      `++++ students: allClasses ${allClasses.map((c) => c.students.length)}, classroomClasses ${classroomClasses.map(
        (c) => c.students.length,
      )}, ${students.length}`,
    );
  }

  const ccmPath = eventSelector({ classroomId: currentClass?.classroomId, classId });
  const eventsByStudent = useAppSelector((state) => state.module.events[ccmPath] || {});
  const isInitialMount = useRef(true);
  const isMounted = useRef(true);
  const unableToFetchEvents = useAppSelector((state) => state.module.unableToFetchEvents);

  /*
   *  On page load or change of module/class, trigger an initial
   *  request to create/update the associated log event store
   *
   *  In dev mode, there will be some duplicate messages
   *  due to double-calls induced by <React.StrictMode>
   *  See file index.tsx.
   *
   *  dt is time difference in seconds for the earliest event.
   */
  useEffect(() => {
    if (currentClass && !fetchingClass) {
      if (debugPrint || pp) console.log(`+++ initial call for ${[currentClass.classroomId, classId]}`);
      dispatch(getEvents({ classroomId: currentClass.classroomId, classId, before }));
    }
  }, [fetchingClass]);

  /*
   *  When an HTTP request is completed (or there is an error), we make a new
   *  request for the latest events.
   *
   *  We are regularly, recursively polling for new events, every 5 seconds.
   *  To account for slow connections, the function does not return until
   *  the request is completed.
   */
  function pollForEvents() {
    setTimeout(() => {
      dispatch(
        getEvents({
          classroomId: currentClass?.classroomId,
          classId,
          before,
        }),
      ).finally(() => {
        if (isMounted.current) pollForEvents();
      });
    }, POLLING);
  }
  useEffect(() => {
    if (!currentClass) dispatch(getClass(classId));
    if (isInitialMount.current) {
      if (debugPrint || pp) console.log('+++ ignore initial call');
      isInitialMount.current = false;
    } else {
      pollForEvents();
      // Stop recursive poll on unmount
      return () => {
        isMounted.current = false;
      };
    }
  }, []);

  if (debugPrint && pp)
    console.log(`+++ events by student ${Object.values(eventsByStudent).map((x) => x.length)}`, eventsByStudent);

  // Versions are fetched in redux/actions based
  // on new log events.
  const moduleVersionDefinitions = useAppSelector((state) => state.module.versionDefinitions);
  const eventVersions = useAppSelector((state) => state.module.eventVersions);
  const fetchingVersionDefinitions = useAppSelector((state) => state.module.fetchingVersionDefinitions);
  useEffect(() => {
    if (!fetchingVersionDefinitions) {
      if (debugPrint) console.log(`**** fetching version start`);
      for (const version of eventVersions) {
        if (!(version in moduleVersionDefinitions)) {
          if (debugPrint) console.log(`**** fetching version ${version}`);
          dispatch(getVersionDefinitions(version));
          break;
        }
      }
    }
  }, [eventVersions, fetchingVersionDefinitions]);
  // End Version & Module Data Section

  if (!currentClass) {
    return (
      <PageContainer header={<Header />} leftNav={<LeftNav currentClassId={classId} />}>
        {!currentClass && classError ? (
          <Alert severity="error" title="Error Retrieving Class Data" blocking={true}>
            {errorMessage && <ExtraAlertText>Error message: {errorMessage}</ExtraAlertText>}
          </Alert>
        ) : (
          <Spinner label="Fetching data ..." center />
        )}
      </PageContainer>
    );
    // The team decided that we don't need the completion circle here
  } else {
    const hideLatencyWarning = !unableToFetchEvents;
    return (
      <PageContainer header={<Header />} leftNav={<LeftNav currentClassId={classId} />}>
        <ActionBar>
          <ClassroomPageTitle
            pageTitle={pageTitle}
            CustomIcon={Icon}
            classOnlyData={classOnlyData}
            classroomClasses={classroomClasses}
            currentClass={currentClass}
          />
          <Actions>
            <Tooltip anchorSelect={'#high-latency-icon'}>
              <Text variant="p">Poor connection. No updates recieved for 20+ seconds.</Text>
            </Tooltip>
            <IconPrimary state={true} hidden={hideLatencyWarning} id={'high-latency-icon'}>
              <LowLatencyIcon style={{ height: '1.5rem' }} />
            </IconPrimary>
            <Button variant="cancel" to={`/class/${classId}`}>
              Return To Class Overview
            </Button>
          </Actions>
        </ActionBar>
        <ChildComponent currentClass={currentClass} students={students} eventsByStudent={eventsByStudent} />
      </PageContainer>
    );
  }
};

const Actions = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
