// @ts-strict-ignore
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import user, { State as UserState } from './reducers/user';
import classReducer, { State as ClassState } from './reducers/class';
import adminReducer, { State as AdminState } from './reducers/admin';
import adminAnalyticsReducer, { State as AdminAnalyticsState } from './reducers/adminAnalytics';
import module, { State as ModuleState } from './reducers/module';
import { DefaultRootState, TypedUseSelectorHook, useDispatch, useSelector, useStore } from 'react-redux';
import { accountManagementReducer, AccountManagementState } from './reducers/accountManagement';

export interface APIState<T> {
  data: T;
  hasError: boolean;
  errorMessage: string | null;
  fetching: boolean;
}

// Redux store and hook types
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;

/**
 * Augmenting react-redux's definition of the default root state allows us to
 * easily use hooks like `useAppSelector` without having to explicitly tell it
 * the type of our state.
 */
declare module 'react-redux' {
  export interface DefaultRootState {
    user: UserState;
    class: ClassState;
    admin: AdminState;
    adminAnalytics: AdminAnalyticsState;
    module: ModuleState;
    accountManagement: AccountManagementState;
  }
}

const reducers = {
  user,
  module,
  class: classReducer,
  admin: adminReducer,
  adminAnalytics: adminAnalyticsReducer,
  accountManagement: accountManagementReducer,
};

const allReducers = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === 'users/signout/fulfilled') {
    state = undefined;
  }
  return allReducers(state, action);
};

export const store = configureStore<DefaultRootState>({
  reducer: rootReducer,
  middleware: [thunkMiddleware],
});

// Redux-specific hooks
// Reference https://react-redux.js.org/using-react-redux/usage-with-typescript
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppStore: () => AppStore = useStore;
