import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import { Header } from '../../components/header';
import { LeftNav } from '../../components/leftNav';
import { Alert } from '../../components/Alert';
import { PageContainer } from '../../components/pageContainer';
import { Text } from '../../components/text';
import { ActionBar } from '../../components/styles';
import { InstitutionTile } from './InstitutionTile';
import { useAppDispatch, useAppSelector } from '../../redux';
import { getRootInstitutionsAction } from '../../redux/actions/accountManagement';
import { primary } from '../../utils/colors';
import { moduleListStyles } from '../moduleList';
import { TextField } from '../../components/textField';
import { institutionSortOrder } from '../../utils/institution';
import Admin from '../../assets/icons/admin.svg';
import { Spinner } from '../../components/Spinner';

export const AccountManagement: React.FC = () => {
  const query = new URLSearchParams(window.location.search);
  const debug = query.has('debug') ? query.get('debug') : null;
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getRootInstitutionsAction(debug));
  }, []);
  const rootInstitutions = useAppSelector((s) => s.accountManagement.rootInstitutions);
  const fetchingRootInstitutions = useAppSelector((s) => s.accountManagement.fetchingRootInstitutions);

  const [hasFetchedAccounts, setHasFetchedAccounts] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setHasFetchedAccounts(!fetchingRootInstitutions && true);
  }, [fetchingRootInstitutions]);

  return (
    <PageContainer header={<Header />} leftNav={<LeftNav />}>
      <ActionBar data-cy="admin-class-access-header">
        <Text variant="lg2" color={primary}>
          <StyledAdminIcon />
          License Management
        </Text>
      </ActionBar>
      {hasFetchedAccounts && (!fetchingRootInstitutions || !!rootInstitutions?.length) && (
        <>
          <RootInstitutions>
            <StyledInput
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              placeholder="Search school, district, et cetera"
            />
            {rootInstitutions
              .filter((rootInstitutions) => {
                return (
                  rootInstitutions.externalName.toLowerCase().includes(search.toLowerCase()) ||
                  (rootInstitutions.nickName && rootInstitutions.nickName.toLowerCase().includes(search.toLowerCase()))
                );
              })
              .sort(institutionSortOrder)
              .map((institution) => (
                <InstitutionTile institution={institution} key={institution.id} />
              ))}
          </RootInstitutions>
          {hasFetchedAccounts && !fetchingRootInstitutions && !rootInstitutions.length && (
            <Alert severity="warning" title="No available institutions" />
          )}
        </>
      )}
      {!hasFetchedAccounts && <Spinner label="Getting institutions ..." center />}
    </PageContainer>
  );
};

const { Modules: RootInstitutions } = moduleListStyles;

const StyledInput = styled(TextField)({
  margin: '1rem',
});

const StyledAdminIcon = styled(Admin)({
  marginRight: '1rem',
});
