import React from 'react';
import { Navigate } from 'react-router-dom';
import styled from '@emotion/styled';

import { Button } from '../components/button';
import { PageContainer } from '../components/pageContainer';
import { Header } from '../components/header';
import { primary } from '../utils/colors';
import { LeftNav } from '../components/leftNav';
import Edit from '../assets/icons/edit.svg';
import { Text } from '../components/text';
import { TextField } from '../components/textField';
import { ActionBar } from '../components/styles';
import { useAppSelector, useAppDispatch } from '../redux';
import { resetPasswordStart } from '../redux/actions/user';
import { UserRole, InstitutionUserRole } from '../../types/models';

export const AccountSettings: React.FC = () => {
  const { email, name, firstName, middleName, lastName, resetPassword, roles, institutionRoles } = useAppSelector(
    (state) => state.user,
  );
  const teacher =
    roles.includes(UserRole.teacher) || institutionRoles.some((ir) => ir.roles.includes(InstitutionUserRole.teacher));
  const dispatch = useAppDispatch();
  const resetPasswordFn = async () => {
    dispatch(resetPasswordStart(email));
  };
  return (
    <PageContainer header={<Header />} leftNav={<LeftNav />}>
      <ActionBar>
        <Text variant="lg2" color={primary}>
          <StyledEdit />
          {teacher ? 'Teacher Account Settings' : 'Student Account Settings'}
        </Text>
      </ActionBar>
      <SettingsContainer>
        <StyledLabel variant="p" bold>
          User name
        </StyledLabel>
        <StyledInput value={name || ''} disabled />
        <StyledLabel variant="p" bold>
          First name
        </StyledLabel>
        <StyledInput value={firstName || ''} disabled />
        <StyledLabel variant="p" bold>
          Middle initial
        </StyledLabel>
        <StyledInput value={middleName || ''} disabled />
        <StyledLabel variant="p" bold>
          Last name
        </StyledLabel>
        <StyledInput value={lastName || ''} disabled />
        <StyledLabel variant="p" bold>
          Email
        </StyledLabel>
        <StyledInput value={email} disabled />
        <Button onClick={resetPasswordFn}>Reset Password</Button>
      </SettingsContainer>
      {resetPassword === 'success' && <Navigate to="/password-reset/email" replace />}
    </PageContainer>
  );
};

const StyledEdit = styled(Edit)({
  marginRight: '1rem',
});

const SettingsContainer = styled.div({
  margin: '1rem',
  maxWidth: '32rem',
});

const StyledLabel = styled(Text)({
  marginBottom: '.5rem',
});

const StyledInput = styled(TextField)({
  marginBottom: '2rem',
});
