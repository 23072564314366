// @ts-strict-ignore
import React, { useMemo } from 'react';

import { SubjectRowLabel, RowDetails } from './StyledComponents';
import { useAppSelector } from '../redux';
import { ModuleLibrary } from '../redux/reducers/module';
import { Class as ClassWithStudents } from '../../types/routes/class';
import { SubjectLeafProps } from './SubjectTree';
import { useLocation } from 'react-router-dom';
import { flatten } from 'lodash';

export const SubjectRow = <T,>({
  subjectId,
  subjectName,
  subjectModules,
  currentClass,
  childLibrary,
  leafData,
  LeafComponent,
}: {
  subjectId: string;
  subjectName: string;
  subjectModules: string[];
  currentClass: ClassWithStudents | null;
  childLibrary: ModuleLibrary;
  leafData: T;
  LeafComponent: React.FC<SubjectLeafProps<T>>;
}) => {
  const debugPrint = false;
  const classId = currentClass ? currentClass.id : null;
  const students = currentClass ? currentClass.students : null;
  const childList = Object.keys(childLibrary);

  // Use localStorage instead of REACT state mechanism
  const statePath = classId ? `class/${classId}/subject-state/${subjectId}` : `shared/subject-state/${subjectId}`;
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  // The main purpose of this logic is to open the details components that are being
  // linked to upon page load. Otherwise, the associated menus may be closed and the
  // linked contents remain unrendered until they are opened manually.
  const forcePathOpen =
    subjectModules.includes(query.get('module')) ||
    flatten(Object.values(childLibrary).map((cl) => cl.modules)).includes(query.get('module'))
      ? true
      : false;
  if (forcePathOpen) window.localStorage.setItem(statePath, JSON.stringify({ open: true }));
  const state = JSON.parse(window.localStorage.getItem(statePath)) || { open: false };

  const subjectClickHandler = () => {
    state.open = !state.open;
    window.localStorage.setItem(statePath, JSON.stringify(state));
  };

  const modulesDefinition = useAppSelector((state) => state.module.definitions.modules);

  const getModule = (moduleId: string) => modulesDefinition.find((module) => module.id == moduleId);

  const moduleParts: { [module: string]: number[] } = {};
  for (const modId of subjectModules) {
    const module = getModule(modId);
    if (module && module.parts) {
      moduleParts[module.id] = module.parts.map((part) => part.modulePart);
    }
  }

  //   const moduleFilter = (_, key: string) =>
  //     modulesDefinition.some(
  //       (module) => module.id == key && module.subjectId == subjectId && Object.keys(moduleParts).includes(module.id),
  //     );

  if (debugPrint)
    console.log(
      `**** class ${classId}, ${subjectId} for modules ${Object.keys(moduleParts)} and ${
        students.length
      } students with ${childList}`,
    );

  return (
    <RowDetails {...(state.open ? { open: true } : {})}>
      <SubjectRowLabel onClick={subjectClickHandler}>{subjectName.toUpperCase()}</SubjectRowLabel>
      {childList.length > 0 &&
        childList.map((subjectId: string) => (
          <SubjectRow
            subjectId={subjectId}
            subjectName={childLibrary[subjectId].name}
            subjectModules={childLibrary[subjectId].modules}
            currentClass={currentClass}
            childLibrary={childLibrary[subjectId].children}
            key={subjectId}
            leafData={leafData}
            LeafComponent={LeafComponent}
          />
        ))}
      <LeafComponent
        subjectId={subjectId}
        subjectModules={subjectModules}
        currentClass={currentClass}
        leafData={leafData}
      />
    </RowDetails>
  );
};
