// @ts-strict-ignore
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Text } from '../../components/text';
import { SubjectToolkits } from './subjectToolkits';
import { useAppDispatch, useAppSelector } from '../../redux';
import { SubjectTree } from '../../components/SubjectTree';
import { PageContainer } from '../../components/pageContainer';
import { Header } from '../../components/header';
import { LeftNav } from '../../components/leftNav';
import { ActionBar } from '../../components/styles';
import { primary } from '../../utils/colors';
import { getDefinitions } from '../../redux/actions/module';
import { Spinner } from '../../components/Spinner';

export const title = 'Toolkits';
export const Toolkits: React.FC = () => {
  const query = new URLSearchParams(window.location.search);
  const debug = query.get('debug') ? true : false;

  const params = useParams();
  const dispatch = useAppDispatch();
  const classId = parseInt(params.classId);
  const modules = useAppSelector((state) => state.module.definitions.modules);
  const fetchingDefinitions = useAppSelector((state) => state.module.fetchingDefinitions);

  useEffect(() => {
    if (!fetchingDefinitions && Object.keys(modules).length === 0) dispatch(getDefinitions());
  }, []);

  const leafDataModuleList = new Set<string>();
  for (const module of modules) {
    if (module.alignedResourcesLink) leafDataModuleList.add(module.id);
  }

  if (fetchingDefinitions || Object.keys(modules).length == 0) {
    return (
      <PageContainer header={<Header />} leftNav={<LeftNav currentClassId={classId} />}>
        <ActionBar>
          <Text variant="lg2" color={primary} data-cy="toolkits-index-header-name">
            Toolkits
          </Text>
        </ActionBar>
        <Spinner label="Fetching toolkits ..." center />
      </PageContainer>
    );
  } else {
    return (
      <PageContainer header={<></>} leftNav={<LeftNav currentClassId={classId} />}>
        <Header />
        <ActionBar>
          <Text variant="lg2" color={primary} data-cy="toolkits-index-header-name">
            Toolkits
          </Text>
        </ActionBar>
        {debug &&
          modules.map((m) => (
            <Text variant={m.alignedResourcesLink ? 'nav' : 'not'} key={m.id}>
              {m.id}
            </Text>
          ))}
        <SubjectTree leafData={null} leafDataModuleList={leafDataModuleList} LeafComponent={SubjectToolkits} />
      </PageContainer>
    );
  }
};
