// @ts-strict-ignore
import React, { useEffect } from 'react';
import { map } from 'lodash';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';

import { Class as ClassWithStudents } from '../../types/routes/class';
import { SubjectRow } from './SubjectRow';
import { useAppSelector, useAppDispatch } from '../redux';
import { ModuleLibrary } from '../redux/reducers/module';
import { Text } from './text';
import { getDefinitions, getLibrary } from '../redux/actions/module';

export interface SubjectLeafProps<T = undefined> {
  subjectId: string;
  subjectModules: string[];
  currentClass: ClassWithStudents | null;
  leafData: T;
}

export const SubjectTree = <T,>({
  leafData,
  leafDataModuleList,
  LeafComponent,
}: {
  leafData: T;
  leafDataModuleList: Set<string>;
  LeafComponent: React.FC<SubjectLeafProps<T>>;
}) => {
  const params = useParams();
  const classId = params.classId ? parseInt(params.classId) : null;
  const modules = useAppSelector((state) => state.module.definitions.modules);
  const libraryState = useAppSelector((state) => state.module.library);
  const fetchingDefinitions = useAppSelector((state) => state.module.fetchingDefinitions);
  const fetchingLibrary = useAppSelector((state) => state.module.fetchingLibrary);
  const currentClass = useAppSelector((state) => state.class.classes.find((c) => c.id === classId));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!fetchingDefinitions && Object.keys(modules).length === 0) dispatch(getDefinitions());
    if (!fetchingLibrary && Object.keys(library).length === 0) dispatch(getLibrary());
  }, []);

  // remove library leaves without any modules in moduleDefinitions
  function rollupRemoveMissingModules(childrenObj: ModuleLibrary) {
    const returnTree = {};
    Object.keys(childrenObj).forEach((sid) => {
      let returnRollup = {};
      const treeNode = childrenObj[sid];
      if (Object.keys(treeNode.children).length > 0) {
        returnRollup = rollupRemoveMissingModules(treeNode.children);
        if (Object.keys(returnRollup).length == 0) {
          return returnTree;
        }
      }
      const validNodeModules = treeNode.modules.filter(
        (x) => modules.some((y) => y.id == x) && leafDataModuleList.has(x),
      );
      if (Object.keys(treeNode.children).length > 0 || validNodeModules.length > 0) {
        returnTree[sid] = {
          name: treeNode.name,
          modules: validNodeModules,
          children: returnRollup,
        };
      }
    });
    return returnTree;
  }
  const library = rollupRemoveMissingModules(libraryState);
  const subjects: string[] = Object.keys(library);

  return (
    <>
      {leafDataModuleList.size < 1 && <PaddedText variant="md">No activity found.</PaddedText>}
      {map(subjects, (subjectId) => (
        <SubjectRow
          subjectId={subjectId}
          subjectName={library[subjectId].name}
          subjectModules={library[subjectId].modules}
          currentClass={currentClass}
          childLibrary={library[subjectId].children}
          key={subjectId}
          leafData={leafData}
          LeafComponent={LeafComponent}
        />
      ))}
    </>
  );
};

const PaddedText = styled(Text)({
  padding: '2rem',
});
