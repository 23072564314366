import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux';
import { Text } from '../components/text';
import { resetPasswordStart, signout } from '../redux/actions/user';
import { Alert } from '../components/Alert';
import { AuthContainer } from '../components/authContainer';
import { Navigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { TextField } from '../components/textField';

import { Button } from '../components/button';

export const ResetPasswordStart: React.FC = () => {
  const { resetPassword } = useAppSelector((state) => state.user);
  const authError = useAppSelector((state) => state.user.authError);
  const authStyle = useAppSelector((state) => state.user.authStyle);
  const [email, setEmail] = useState('');
  const dispatch = useAppDispatch();
  const resetPasswordFn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(resetPasswordStart(email));
  };
  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);
  useEffect(() => {
    dispatch(signout());
  }, []);
  return (
    <AuthContainer title="Enter your email">
      {resetPassword === 'success' && <Navigate to="/password-reset/email" replace />}
      <StyledForm onSubmit={resetPasswordFn}>
        {authError && <Alert severity={authStyle}>{authError}</Alert>}
        <StyledLabel variant="p">Email</StyledLabel>
        <StyledInput placeholder="Email" type="email" value={email} onChange={onEmailChange} />
        <StyledButton type="submit">Send Reset Password Email</StyledButton>
      </StyledForm>
    </AuthContainer>
  );
};

const StyledForm = styled.form({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const StyledLabel = styled(Text)({
  marginBottom: '.5rem',
});

const StyledInput = styled(TextField)({
  marginBottom: '2rem',
});

const StyledButton = styled(Button)({
  width: '100%',
  marginBottom: '2rem',
});
