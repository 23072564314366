import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import { useAppDispatch } from '../redux';
import { resetPasswordReset } from '../redux/actions/user';
import { AuthContainer } from '../components/authContainer';
import { Button } from '../components/button';

export const ResetPasswordSuccess: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(resetPasswordReset());
  });
  return (
    <AuthContainer title="Password Reset Successfully">
      <StyledButton to="/">Return to login</StyledButton>
    </AuthContainer>
  );
};

const StyledButton = styled(Button)({
  width: '12rem',
  margin: '2rem auto 0 auto',
  display: 'flex',
  justifyContent: 'center',
});
