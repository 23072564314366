// @ts-strict-ignore
import styled from '@emotion/styled';
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AllUsage } from './AllUsage';

import { useAppDispatch, useAppSelector } from '../../redux';
import { getLibrary, getDefinitions } from '../../redux/actions/module';
import { getRootInstitutionsAction } from '../../redux/actions/accountManagement';
import { getModuleUsageAction } from '../../redux/actions/adminAnalytics';
import { primary } from '../../utils/colors';
import { Alert } from '../../components/Alert';
import { Header } from '../../components/header';
import { LeftNav } from '../../components/leftNav';
import { PageContainer } from '../../components/pageContainer';
import { Text } from '../../components/text';
import { ActionBar } from '../../components/styles';
import { Filter as FilterComponent } from './Filter';
import { Filters, makeFilter } from '../../api/adminAnalytics';
import Admin from '../../assets/icons/admin.svg';
import { configPublic } from '../../../config-public';
import { URL_QUERY_ARRAY_JOINER } from '../../utils/query';

const ADMIN_ANALYTICS_PATH = '/admin/analytics';

export const AdminAnalytics: React.FC = () => {
  const debugPrint = false;
  const query = new URLSearchParams(window.location.search);
  const debug = query.has('debug') ? query.get('debug') : null;
  const districts = useAppSelector((s) => s.accountManagement.rootInstitutions);
  const fetchingRootInstitutions = useAppSelector((s) => s.accountManagement.fetchingRootInstitutions);
  const { search } = useLocation();
  const navigate = useNavigate();
  const [filters, setFilters] = React.useState<Filters>(makeFilter());
  const showPopup = useAppSelector((state) => state.adminAnalytics.showPopup);
  const modules = useAppSelector((state) => state.module.definitions);
  const fetchingDefinitions = useAppSelector((state) => state.module.fetchingDefinitions);
  const libraryState = useAppSelector((state) => state.module.library);

  const fetchingLibrary = useAppSelector((state) => state.module.fetchingLibrary);
  const isFirstFilter = useRef(true);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (districts.length == 0 && !fetchingRootInstitutions) {
      if (debugPrint) console.log('=== dispatch root institutions');
      dispatch(getRootInstitutionsAction(debug));
    }

    if (Object.keys(modules).length === 0 && !fetchingDefinitions) {
      if (debugPrint) console.log('=== dispatch module definitions');
      dispatch(getDefinitions());
    }

    if (Object.keys(libraryState).length === 0 && !fetchingLibrary) dispatch(getLibrary());

    // On page load, update filters based on URL query,
    if (search) {
      const query = new URLSearchParams(search);
      const newFilters: Filters = { institutions: [], classes: [] };
      for (const xx of ['start', 'end'])
        if (query.has(xx)) {
          newFilters[xx] = new Date(1000 * Number(query.get(xx)));
        }
      for (const xx of ['institutions', 'classes'])
        if (query.has(xx)) {
          // Always change.  We could test for set equality here.
          const z = query
            .get(xx)
            .split(URL_QUERY_ARRAY_JOINER)
            .map((y: string) => parseInt(y));
          if (debugPrint) console.log(`<<<<< set ${xx} filter ${JSON.stringify(z)}`);
          newFilters[xx] = z;
        }
      if (query.has('strategy')) newFilters['strategy'] = query.get('strategy');
      if (debugPrint) console.log('===== use URL for initial filter');
      setFilters({ ...newFilters });
      if (newFilters.institutions.length + newFilters.classes.length > 0) {
        if (debugPrint) console.log('+++++ getting stats using URL filter');
        dispatch(getModuleUsageAction({ filters: newFilters, debug }));
      }
    }
  }, []);

  // On page load, if there is no query string and only
  // a few root institutions are defined, start with all
  // root institutions selected.
  useEffect(() => {
    if (filters.institutions.length + filters.classes.length == 0 && districts.length > 0 && districts.length < 5) {
      // If not many districts, get stats for all districts.
      filters.institutions = districts.map((x) => x.id);
      setFilters({ ...filters });
      if (debugPrint) console.log(`===== use districts for initial filter ${JSON.stringify(filters.institutions)}`);
      if (debugPrint) console.log('+++++ getting stats using defaults');
      dispatch(getModuleUsageAction({ filters, debug }));
    }
  }, [districts]);

  // Update URL based on filter changes.
  // But not on initial page load.
  useEffect(() => {
    if (isFirstFilter.current) {
      isFirstFilter.current = false;
    } else if (window.location.pathname == ADMIN_ANALYTICS_PATH) {
      const query = new URLSearchParams(window.location.search);
      for (const xx of ['start', 'end']) {
        if (filters[xx]) query.set(xx, Math.floor(filters[xx].getTime() / 1000).toString());
        else query.delete(xx);
      }
      for (const xx of ['institutions', 'classes']) {
        if (filters[xx].length > 0) {
          const z = filters[xx].join(URL_QUERY_ARRAY_JOINER);
          query.set(xx, z);
        } else query.delete(xx);
      }
      if (query.toString() != search.slice(1)) {
        if (debugPrint) console.log(`>>>>> update URL from ${search || '""'} to ${query.toString()}`);
        navigate({
          pathname: ADMIN_ANALYTICS_PATH,
          search: query.toString(),
        });
      } else {
        if (debugPrint) console.log(`>>>> don't update URL, query unchanged`);
      }
    } else {
      if (debugPrint) console.log(`>>>> don't update URL for ${window.location.pathname}`);
    }
  }, [filters]);

  return (
    <PageContainer header={<Header />} leftNav={<LeftNav />}>
      <ActionBar data-cy="admin-class-access-header">
        <TitleText variant="lg2" color={primary}>
          <StyledAdminIcon />
          Administrator Analytics
        </TitleText>
        <FilterComponent filters={filters} setFilters={setFilters} rootInstitutions={districts} />
      </ActionBar>
      <AllUsage />
      <Alert isOpen={showPopup} severity="error" title="Error Retrieving Module Data" blocking={true}>
        {'Module data could not be retrieved.  This could be due to a loss of network connection.  Please send a screenshot to ' +
          configPublic.supportEmail.staff +
          '.'}
      </Alert>
    </PageContainer>
  );
};

const TitleText = styled(Text)({
  whiteSpace: 'nowrap',
});

const StyledAdminIcon = styled(Admin)({
  marginRight: '1rem',
});
