import React from 'react';
import { primary, charcoal15 } from '../utils/colors';
import styled from '@emotion/styled';
import { generate } from 'randomstring';
import { TextField } from './textField';
import { Text } from '../components/text';

interface Props {
  title?: string;
  options?: Array<string>;
  value: string | null;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  other?: boolean;
}

export const RadioButtonGroup: React.FC<Props> = ({
  title = '',
  options = [],
  onChange = () => {},
  other = false,
  value,
}) => {
  const uniqueName = generate({
    length: 10,
    capitalization: 'lowercase',
  });
  const otherTextLabel = 'Other';
  const otherTextInputLabel = 'Please specify *';

  const choseOther = typeof value == 'string' && !options.includes(value);

  return (
    <StyledRadioButtonGroup>
      {title && <StyledTitle variant="p">{title}</StyledTitle>}
      {options.map((opt, index) => {
        return (
          <StyledRadioLabel key={index} color={value === opt ? primary : charcoal15}>
            <Text variant="p">{opt}</Text>
            <StyledRadioButtonHidden
              type="radio"
              checked={value === opt}
              value={opt}
              name={uniqueName}
              onChange={onChange}
            />
            <StyledRadioButtonSpan></StyledRadioButtonSpan>
          </StyledRadioLabel>
        );
      })}
      {other && (
        <StyledRadioLabel color={choseOther ? primary : charcoal15}>
          {' '}
          <Text variant="p">{otherTextLabel}</Text>
          <StyledRadioButtonHidden type="radio" checked={choseOther} value="" name={uniqueName} onChange={onChange} />
          <StyledRadioButtonSpan></StyledRadioButtonSpan>
        </StyledRadioLabel>
      )}
      {other && choseOther && (
        <StyledTitle variant="p">
          <StyledTextLabel variant="p">{otherTextInputLabel}</StyledTextLabel>
          <TextField
            data-cy="radio-button-group-other-text"
            type="text"
            value={value}
            placeholder={otherTextLabel}
            onChange={onChange}
          />
        </StyledTitle>
      )}
    </StyledRadioButtonGroup>
  );
};

const StyledRadioButtonGroup = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '2rem',
});

const StyledTitle = styled(Text)({
  marginBottom: '1rem',
});

const StyledTextLabel = styled(Text)({
  marginBottom: '.5rem',
});

const radioButtonSize = 18;

const StyledRadioButtonSpan = styled.span({
  position: 'absolute',
  top: '0',
  left: '0',
  height: String(radioButtonSize) + 'px',
  width: String(radioButtonSize) + 'px',
  backgroundColor: charcoal15,
  borderRadius: '50%',
  ':after': {
    content: '""',
    position: 'absolute',
    display: 'none',
    top: String(radioButtonSize / 3) + 'px',
    left: String(radioButtonSize / 3) + 'px',
    width: String(radioButtonSize / 3) + 'px',
    height: String(radioButtonSize / 3) + 'px',
    borderRadius: '50%',
    background: charcoal15,
  },
});

const StyledRadioButtonHidden = styled.input({
  position: 'absolute',
  opacity: 0,
  cursor: 'pointer',
  height: 0,
  width: 0,
});

// container
const StyledRadioLabel = styled.label((props) => ({
  display: 'block',
  position: 'relative',
  paddingLeft: '35px',
  marginBottom: '1rem',
  cursor: 'pointer',
  fontSize: '12px',
  userSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
  WebkitUserSelect: 'none',
  div: {
    marginTop: '0',
  },
  span: {
    backgroundColor: props.color,
    ':after': {
      display: props.color ? 'block' : 'none',
    },
  },
  ':hover': {
    color: primary,
    span: {
      backgroundColor: primary,
    },
  },
}));
