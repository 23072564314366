// @ts-strict-ignore
import React, { useState, useEffect, SetStateAction, Dispatch } from 'react';
import styled from '@emotion/styled';

import { black, gray } from '../../utils/colors';
import { textStyle, Text } from '../../components/text';
import { LicenseStatusText } from './types';
import { Contract } from '../../../types/routes/accountManagement';

import { primary } from '../../utils/colors';
import { CTAItem, Modal } from '../../components/modal';
import { displayName } from '../../utils/institution';

import {
  Block,
  LicenseUsageContainer,
  LicenseUsageFlexBox,
  LicenseUsageStats,
  LicenseUsageText,
  StyledCompletionCircle,
} from '../../components/StyledComponents';
import { INCLUDE_RESERVED, usedText } from './util';
import { useAppDispatch, useAppSelector } from '../../redux';
import { getInstitutionAction } from '../../redux/actions/accountManagement';
import { Spinner } from '../../components/Spinner';

export const current = (contract: Contract): boolean => {
  const now = new Date();
  return contract.contractStartDate < now && (!contract.contractEndDate || contract.contractEndDate > now);
};

export const LicenseUsage: React.FC<{
  contract: Contract;
  parentId: number;
  filter: Contract[];
  setFilter: Dispatch<SetStateAction<Contract[]>>;
}> = ({ contract, parentId, filter, setFilter }) => {
  const debugPrint = false;
  const query = new URLSearchParams(window.location.search);
  const debug = query.has('debug') ? query.get('debug') : null;
  const dispatch = useAppDispatch();
  const institution = useAppSelector((s) => s.accountManagement.institutions[contract.institutionId]);
  const [isLicensesUsedOpen, setIsLicensesUsedOpen] = useState(false);

  enum LicenseType {
    default = 'default',
    math = 'math',
    science = 'science',
  }

  const licenseLabel = {
    [LicenseType.default]: 'Dual',
    [LicenseType.math]: 'Math',
    [LicenseType.science]: 'Science',
  };

  useEffect(() => {
    if (!institution) {
      if (debugPrint) console.log(`--- dispatch root institution ${contract.institutionId}`);
      dispatch(getInstitutionAction(contract.institutionId));
    }
  }, [institution]);

  const used = contract.consumed + INCLUDE_RESERVED * contract.reserved;
  const licenseUsagePercent = contract.licensesIssued !== 0 ? (used / contract.licensesIssued) * 100 : null;

  const hideLicensesUsed = () => {
    setIsLicensesUsedOpen(false);
  };

  const licensesUsedCtas: CTAItem[] = [
    {
      text: 'Ok',
      onClick: hideLicensesUsed,
    },
  ];

  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };

  const checkboxHandler = (): void => {
    if (filter.some((c) => c.id == contract.id)) setFilter(filter.filter((x) => x.id != contract.id));
    else setFilter([...filter, contract]);
  };

  const all = contract.reserved + contract.unreserved + contract.consumed;

  if (institution)
    return (
      <LicenseUsageStats>
        <Top>
          <Label onClick={(e) => e.stopPropagation()} color={current(contract) ? black : gray}>
            <input
              type="checkbox"
              name={displayName(institution)}
              onChange={checkboxHandler}
              checked={filter.some((c) => c.id == contract.id)}
            />
            {'Contract' +
              (debug ? ' (' + contract.id + ')' : '') +
              (contract.institutionId != parentId ? ' For ' + displayName(institution) : '')}
          </Label>
          <Right>
            <LicensePill>{`${licenseLabel[contract.licenseType]} License`}</LicensePill>
          </Right>
        </Top>

        <Text variant="nav">
          Start: {`${contract.contractStartDate.toLocaleDateString(undefined, options)}`},&nbsp;&nbsp; End:{' '}
          {`${contract.contractEndDate ? contract.contractEndDate.toLocaleDateString(undefined, options) : 'Never'}`}
        </Text>
        <LicenseUsageFlexBox>
          <LicenseUsageContainer>
            <StyledCompletionCircle completionPercentage={licenseUsagePercent} text="License Usage" />
          </LicenseUsageContainer>
          <LicenseUsageContainer>
            <Block>
              <LicenseUsageText variant="p" color={primary}>
                <b>{contract.licensesIssued}</b>{' '}
                {contract.licensesIssued > 1 ? LicenseStatusText.purchasedPlural : LicenseStatusText.purchased}
              </LicenseUsageText>
              <LicenseUsageText variant="p" color={primary}>
                <b>{used}</b> {usedText(used)}
              </LicenseUsageText>
              {debug && <LicenseUsageText variant="nav">{contract.reserved} reserved</LicenseUsageText>}
              <LicenseUsageText variant="p" color={primary}>
                <b>{all}</b> {all > 1 ? LicenseStatusText.allPlural : LicenseStatusText.all}
              </LicenseUsageText>
            </Block>
          </LicenseUsageContainer>
        </LicenseUsageFlexBox>
        <Modal
          isOpen={isLicensesUsedOpen}
          closeModal={hideLicensesUsed}
          ctas={licensesUsedCtas}
          title={`${contract.consumed} License${contract.consumed === 1 ? '' : 's'} Used`}
        ></Modal>
      </LicenseUsageStats>
    );
  else return <Spinner label="Getting institution ..." center />;
};

const Top = styled.div({
  width: '100%',
  position: 'relative',
  margin: 0,
});
const Right = styled.div({
  position: 'absolute',
  right: 0,
  top: 0,
  margin: 0,
});

const Label = styled.label({
  ...textStyle('md'),
  fontWeight: 'bold',
});

const LicensePill = styled('div')({
  ...textStyle('normal'),
  color: 'white',
  padding: '0.25rem 0.5rem 0.25rem 0.5rem',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: primary,
  borderRadius: '0.5rem',
  margin: '0.1rem 0.2rem 0.1rem 0.2rem',
  whiteSpace: 'nowrap',
  backgroundColor: primary,
  display: 'inline-block',
});
