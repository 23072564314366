// @ts-strict-ignore
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from '../../redux';
import { getDefinitions } from '../../redux/actions/module';
import { charcoal15, white } from '../../utils/colors';
import { SubjectRowLabel } from '../StyledComponents';
import { SubjectTaskSelector } from './SubjectTaskSelector';
import { SubjectTree } from '../SubjectTree';
import { TaskDefinition } from '../../../types/routes/module';
import { Spinner } from '../Spinner';
import { ConfirmModalState } from '../../pages/liveClassroom/types';

export interface ModulePartTaskSelection {
  moduleId: string;
  part?: number;
  taskId?: string;
}
export interface SetSelectedTask {
  (data?: ModulePartTaskSelection): void;
}

export interface ShowSetSelectedTask {
  showTasks: boolean;
  setSelectedTask: SetSelectedTask;
  selectedTask?: ModulePartTaskSelection;
  // return true if the task should be enabled (default behavior) and false if disabled
  taskFilter?: (task: TaskDefinition, taskIndex: number) => boolean;
  taskDisabledReason?: string;
  firstTaskIsPart: boolean;
  setConfirmModalState?: (state: ConfirmModalState) => void;
}

export const title = '';
export const TaskSelector = (data: ShowSetSelectedTask) => {
  const dispatch = useAppDispatch();
  const modules = useAppSelector((state) => state.module.definitions.modules);
  const fetchingDefinitions = useAppSelector((state) => state.module.fetchingDefinitions);

  useEffect(() => {
    if (!fetchingDefinitions && Object.keys(modules).length === 0) dispatch(getDefinitions());
  }, []);

  const leafDataModuleList = new Set<string>();
  for (const module of modules) {
    leafDataModuleList.add(module.id);
  }
  if (fetchingDefinitions || Object.keys(modules).length == 0) {
    return (
      <FloatingContainer>
        <Spinner label="Fetching tasks ..." center />
      </FloatingContainer>
    );
  } else {
    return (
      <FloatingContainer>
        <RowDiv>
          <SubjectRowNoneLabel onClick={() => data.setSelectedTask()} data-cy="component-task-selector-none">
            NONE
          </SubjectRowNoneLabel>
        </RowDiv>
        <SubjectTree<ShowSetSelectedTask>
          leafData={data}
          leafDataModuleList={leafDataModuleList}
          LeafComponent={SubjectTaskSelector}
        />
      </FloatingContainer>
    );
  }
};

export const TaskSelectorBackdrop: React.FC<{ clickedBackdrop: () => void }> = ({ clickedBackdrop }) => {
  return <FloatingContainerBackdrop onClick={() => clickedBackdrop()}></FloatingContainerBackdrop>;
};

const SubjectRowNoneLabel = styled(SubjectRowLabel)({
  cursor: 'pointer',
});

const FloatingContainer = styled.div({
  position: 'absolute',
  top: '20vh',
  margin: '0',
  padding: '0',
  background: white,
  boxShadow: '0 1rem 4rem 0 rgba(0,0,0,0.3)',
  // zIndex 1000 is for confirmation modal when needed
  zIndex: 999,
  height: '80vh',
  border: `1px solid ${charcoal15}`,
  borderRadius: '3px',
  overflowY: 'scroll',
  minWidth: '18rem',
  maxWidth: '25rem',
  width: '30vw',
});

const RowDiv = styled.div({
  border: `1px solid ${charcoal15}`,
  borderRadius: 3,
  margin: '1rem',
  padding: '1rem',
});

const FloatingContainerBackdrop = styled.div({
  position: 'fixed',
  margin: '0',
  padding: '0',
  top: '0',
  bottom: '0',
  right: '0',
  left: '0',
  background: 'rgba(0,0,0,0.1)',
  height: '100vh',
  width: '100vw',
  zIndex: 998,
});
