// @ts-strict-ignore
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { find } from 'lodash';
import styled from '@emotion/styled';

import { useAppDispatch, useAppSelector } from '../../redux';
import { Header } from '../../components/header';
import { LeftNav } from '../../components/leftNav';
import { Button } from '../../components/button';
import { PageContainer } from '../../components/pageContainer';
import { Alert, ExtraAlertText } from '../../components/Alert';
import { Text } from '../../components/text';
import { ActionBar } from '../../components/styles';
import { charcoal05, charcoal15, primary } from '../../utils/colors';
import Target from '../../assets/icons/target.svg';
import { getDefinitions, getLibrary } from '../../redux/actions/module';
import { get as getClass, resetExternalUpdate } from '../../redux/actions/class';
import { SubjectRow } from './SubjectRow';
import { ModuleLibrary } from '../../redux/reducers/module';
import { configPublic } from '../../../config-public';
import { Spinner } from '../../components/Spinner';
import { classDisplayName } from '../../utils/class';

export const ModuleList: React.FC = () => {
  const params = useParams();
  const { classId } = params;
  const currentClass = useAppSelector((state) => find(state.class.classes, (c) => c.id === parseInt(classId)));
  const fetchingClasses = useAppSelector((state) => state.class.fetchingClasses);
  const fetchingClass = useAppSelector((state) => state.class.fetchingClass);
  const hasError = useAppSelector((state) => state.class.hasError);
  const errorMessage = useAppSelector((state) => state.class.errorMessage);
  const modules = useAppSelector((state) => state.module.definitions.modules);
  const fetchingDefinitions = useAppSelector((state) => state.module.fetchingDefinitions);
  const libraryState = useAppSelector((state) => state.module.library);
  const fetchingLibrary = useAppSelector((state) => state.module.fetchingLibrary);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!fetchingDefinitions && Object.keys(modules).length === 0) dispatch(getDefinitions());
    if (!fetchingLibrary && Object.keys(library).length === 0) dispatch(getLibrary());
  }, []);

  useEffect(() => {
    if (!currentClass && !fetchingClasses && !fetchingClass && !hasError) dispatch(getClass(parseInt(classId)));
  }, [fetchingClasses, fetchingClass]);

  // remove library leaves without any modules in moduleDefinitions
  const modulesList = modules.map((x) => x.id);
  function rollupRemoveMissingModules(childrenObj: ModuleLibrary) {
    const returnTree = {};
    Object.keys(childrenObj).forEach((sid) => {
      let returnRollup = {};
      const treeNode = childrenObj[sid];
      if (Object.keys(treeNode.children).length > 0) {
        returnRollup = rollupRemoveMissingModules(treeNode.children);
        if (Object.keys(returnRollup).length == 0) {
          return returnTree;
        }
      }
      const validNodeModules = treeNode.modules.filter((x) => modulesList.includes(x));
      if (Object.keys(treeNode.children).length > 0 || validNodeModules.length > 0) {
        returnTree[sid] = { name: treeNode.name, modules: validNodeModules, children: returnRollup };
      }
    });
    return returnTree;
  }
  const library = rollupRemoveMissingModules(libraryState);
  const subjects: string[] = Object.keys(library);

  return (
    <PageContainer header={<Header />} leftNav={<LeftNav currentClassId={parseInt(classId)} />}>
      <ActionBar>
        <Text variant="lg2" color={primary}>
          <StyledTarget />
          {currentClass ? classDisplayName(currentClass, { section: true }) : ''}
        </Text>
      </ActionBar>
      <Alert
        isOpen={hasError}
        closeAlert={() => {
          dispatch(resetExternalUpdate());
        }}
        severity="error"
        title="Error"
        blocking={true}
      >
        {'Class data could not be retrieved.  This could be due to a loss of network connection.  Please send a screenshot to ' +
          configPublic.supportEmail.staff +
          '.'}
        {errorMessage && <ExtraAlertText>Error message: {errorMessage}</ExtraAlertText>}
      </Alert>
      <Actions>
        <Button to={`/class/${classId}/progress`}>Class Progress</Button>

        <Button disabled={!currentClass} to={`/class/${classId}`}>
          Update/View Class
        </Button>
      </Actions>
      {subjects.length > 0 ? (
        subjects.map((subjectId: string, index: number) => (
          <SubjectRow
            subjectId={subjectId}
            subjectName={library[subjectId].name}
            classId={classId}
            modules={modules}
            childLibrary={library[subjectId].children}
            key={subjectId + index.toString()}
            index={subjectId + index.toString()}
          />
        ))
      ) : (
        <Spinner label="Fetching data ..." center />
      )}
    </PageContainer>
  );
};

const Actions = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '1rem',
});

const StyledTarget = styled(Target)({
  marginRight: '1rem',
});

const Modules = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '1rem 1rem',
});

const Module = styled.div({
  border: `1px solid ${primary}`,
  borderRadius: 3,
  boxShadow: `0 5px 10px ${charcoal15}`,
  position: 'relative',
  margin: '1rem',
});

const ModuleContent = styled(Link)({
  textDecoration: 'none',
  // The bottom padding gives room for the button bar.
  padding: '1.25rem 1.25rem 6.25rem',
  width: '21.375rem',
  display: 'block',
  cursor: 'pointer',
});

const ImageContainer = styled.div({
  backgroundColor: charcoal05,
  height: '12rem',
  marginBottom: '1rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Title = styled(Text)({
  marginBottom: '.25rem',
});

export const moduleListStyles = {
  Modules,
  Module,
  ModuleContent,
  ImageContainer,
  Title,
};
