import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux';
import { signout as signoutAction } from '../redux/actions/user';

import { UserRole, InstitutionUserRole, UserAccountStatus } from '../../types/models';
import { useNavigate } from 'react-router-dom';
import { AuthOverlay } from '../components/authOverlay';
import { AuthContainer } from '../components/authContainer';
import { Button } from '../components/button';
import { Text } from '../components/text';
import styled from '@emotion/styled';

export const Root: React.FC = () => {
  const debugPrint = false;
  const status = useAppSelector((state) => state.user.status);
  const fetchedUser = useAppSelector((state) => state.user.fetchedUser);
  const fetchingUser = useAppSelector((state) => state.user.fetchingUser);
  const authErrorHttpCode = useAppSelector((state) => state.user.authErrorHttpCode);
  const authError = useAppSelector((state) => state.user.authError);
  const dashboard = useAppSelector((state) => state.user.dashboard);
  const dashboardLoaded = useAppSelector((state) => state.user.dashboardLoaded);
  const roles = useAppSelector((state) => state.user.roles);
  const institutionRoles = useAppSelector((state) => state.user.institutionRoles);
  // UserRole.teacher is deprecated in favor of InstitutionUserRole.teacher
  const teacher =
    roles.includes(UserRole.teacher) || institutionRoles.some((ir) => ir.roles.includes(InstitutionUserRole.teacher));
  const institutionAdmin = institutionRoles.some((x) => x.roles.includes(InstitutionUserRole.institutionAdmin));

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const returnToLogin = () => {
    dispatch(signoutAction());
    navigate('/login');
  };

  useEffect(() => {
    if (!fetchingUser) {
      if (debugPrint)
        console.log(`*** root effect fetchingUser ${fetchingUser}, fetchedUser ${fetchedUser}, status ${status}`);
      if (fetchedUser && status) {
        if (status == UserAccountStatus.disabled) {
          dispatch(signoutAction());
          setError('Account disabled');
        }
        if (status == UserAccountStatus.pending) navigate('/profile');
        else if (dashboardLoaded) {
          if (teacher) {
            if (dashboard?.tutorialVideoViewed) navigate('/class-list');
            else navigate('/tutorial-video');
          } else if (institutionAdmin) navigate('/admin/licenses');
          else navigate('/device-access');
        } else {
          /* This means that the dashboard state call failed.
           * We don't need to inform the user of this:
           * just use the default state.
           *
           * Ideally, this would be logged on the server.
           */
          setError('Dashboard state request failed, please try logging in again.');
          console.warn('Server call for the dashboard state failed');
        }
      } else {
        if (authErrorHttpCode == 401) navigate('/login');
        else setError(authError || 'An unknown authorization error occured, please contact support@prismsvr.com.');
      }
    }
  }, [fetchingUser, fetchedUser]);

  if (debugPrint)
    console.log(
      `*** root execute fetchingUser ${fetchingUser}, fetchedUser ${fetchedUser}, error ${error ? true : false}`,
    );

  if (fetchingUser || !error) return <AuthOverlay />;

  return (
    <AuthContainer title="Error">
      <Text variant="p">{error}</Text>
      <StyledButton onClick={returnToLogin} data-cy="return-to-login">
        Return to Login
      </StyledButton>
      {/* login button */}
    </AuthContainer>
  );
};

const StyledButton = styled(Button)({
  width: '12rem',
  margin: '2rem auto',
});
