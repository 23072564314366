import styled from '@emotion/styled';
import React, { Dispatch, useState } from 'react';
import Alert from '@mui/material/Alert';

import { useAppSelector } from '../../../redux';
import { Filters } from '../../../api/adminAnalytics';
import { white, black } from '../../../utils/colors';
import { Button } from '../../../components/button';
import { institutionSortOrder } from '../../../utils/institution';

import { Text } from '../../../components/text';
import { TextField } from '../../../components/textField';
import { InstitutionsSelector } from './InstitutionsSelector';
import { Institution } from '../../../../types/routes/accountManagement';
import { configPublic } from '../../../../config-public';

/*
 * This is the popup giving a tree of institutions and classes
 * to select from.
 */

interface Props {
  rootInstitutions: Institution[];
  filters: Filters;
  setFilters: Dispatch<React.SetStateAction<Filters>>;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
  debugPrint: boolean;
}

export const FilterSelector = ({ filters, setFilters, setIsOpen, rootInstitutions, debugPrint }: Props) => {
  // Sorting in-place causes a REACT error.
  const sorted = rootInstitutions.slice();
  sorted.sort(institutionSortOrder);
  const fetchingAllInstitutions: boolean = useAppSelector((s) => s.accountManagement.fetchingRootInstitutions);
  const fetchingModuleUsage: boolean = useAppSelector((s) => s.adminAnalytics.fetchingModuleUsage);
  const hasError: boolean = useAppSelector((s) => s.accountManagement.hasError);
  const [startInvalid, setStartInvalid] = useState(false);
  const [endInvalid, setEndInvalid] = useState(false);

  const applyFiltersHandler = () => {
    setIsOpen(false);
  };

  // BvdS:  also copy in adminClassAccess/RootInstitution
  const dateHandler = (eventValue: string, type: 'start' | 'end'): void => {
    const d = eventValue.trim() == '' ? null : new Date(eventValue.trim());
    if (!d || !isNaN(d.getTime())) {
      setFilters({ ...filters, [type]: d });
      if (debugPrint) console.log(`---- update filters start ${filters.start} end ${filters.end}`);
    } else {
      if (type == 'start') setStartInvalid(true);
      else if ((type = 'end')) setEndInvalid(true);
    }
    if (debugPrint) console.log(`+++ ${type} value ${d}`);
  };

  const clearAllCheckboxesHandler = (): void => {
    // preserve dates, clear checkboxes
    setFilters({ ...filters, institutions: [], classes: [] });
  };

  const ancestors: number[] = [];

  return (
    <>
      {hasError && (
        <Alert severity="error" title="Error loading institutions">
          {'Institution data could not be retrieved.  This could be due to a loss of network connection.  Please send a screenshot to ' +
            configPublic.supportEmail.staff +
            '.'}
        </Alert>
      )}

      <div style={{ display: rootInstitutions && !fetchingAllInstitutions ? 'block' : 'none' }}>
        {!hasError && (
          <StyledButton disabled={fetchingModuleUsage} onClick={applyFiltersHandler}>
            <Text variant={'p'} color={white}>
              Filter
            </Text>
          </StyledButton>
        )}

        <Text variant="p">Start:</Text>
        {startInvalid && <DateFormatHelp />}
        <StyledInput
          defaultValue={filters.start?.toISOString().substring(0, 10) || ''}
          onFocus={() => setStartInvalid(false)}
          onBlur={(e) => dateHandler(e.target.value, 'start')}
        ></StyledInput>

        <Text variant="p">End:</Text>
        {endInvalid && <DateFormatHelp />}
        <StyledInput
          defaultValue={filters.end?.toISOString().substring(0, 10) || ''}
          onFocus={() => setEndInvalid(false)}
          onBlur={(e) => dateHandler(e.target.value, 'end')}
        ></StyledInput>

        {!fetchingModuleUsage && (
          <StyledClickableText variant={'p'} onClick={clearAllCheckboxesHandler}>
            Clear All
          </StyledClickableText>
        )}

        {sorted.map((institution: Institution, index) => (
          <InstitutionsSelector
            key={index}
            filters={filters}
            ancestors={ancestors}
            setFilters={setFilters}
            institution={institution}
            debugPrint={debugPrint}
          />
        ))}
      </div>
    </>
  );
};

export const DateFormatHelp: React.FC = () => {
  return (
    <Text variant="sm" color="red">
      Use format &ldquo;2022-04-23&rdquo; or leave blank
    </Text>
  );
};

const StyledButton = styled(Button)({
  display: 'block',
  width: '100%',
  textAlign: 'center',
  marginBottom: '1rem',
});

const StyledClickableText = styled(Text)({
  display: 'block',
  width: '6rem',
  padding: '0.2rem',
  background: white,
  color: black,
  textAlign: 'center',
  margin: '1rem',
  border: '1px solid grey',
  borderRadius: '.3rem',
  cursor: 'pointer',
});

export const StyledInput = styled(TextField)({
  marginBottom: '1rem',
  width: '12rem',
});
