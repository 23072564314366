import React, { useEffect } from 'react';
import { useAppDispatch } from '../redux';
import { Text } from '../components/text';
import { resetPasswordReset } from '../redux/actions/user';
import { AuthContainer } from '../components/authContainer';

export const ResetPasswordEmail: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(resetPasswordReset());
  });
  return (
    <AuthContainer title="Password Reset Email Sent">
      <Text variant="md">Please check your email to finish resetting your password.</Text>
    </AuthContainer>
  );
};
