// @ts-strict-ignore
import React from 'react';
import styled from '@emotion/styled';
import { LicenseStatus } from '../../types/models';
import { LicenseStatusText } from '../pages/licenseManagement/types';
import { Contract, Student } from '../../types/routes/accountManagement';
import { useAppSelector, useAppDispatch } from '../redux';
import { updateLicense } from '../redux/actions/class';
import { textStyle } from './text';
import { CustomTooltip as Tooltip } from '../components/tooltip';

const hoverOverReserved = <p>Click here to unreserve this student’s license</p>;
const hoverOverUnreserved = <p>Click here to reserve this student’s license</p>;
const hoverOverNoAvailable = (
  <p>
    There are no more licenses available in your contract.
    <br />
    Please contact support@prismsvr.com
  </p>
);
const hoverOverOtherContract = <p>This license is for a different contract.</p>;
const hoverOverNoContract = (
  <p>
    No contract assigned.
    <br />
    Please contact support@prismsvr.com
  </p>
);
const hoverOverNotClickable = null;

export interface User {
  name: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  email: string;
  key?: string;
  contractId?: number;
  licenseStatus?: LicenseStatus;
  id?: number;
}

export const LicenseCheck: React.FC<{
  classId: number;
  student: Student | User;
  contracts: Contract[] | null;
  clickable: boolean;
}> = ({ classId, student, contracts, clickable }) => {
  const debugPrint = false;
  const dispatch = useAppDispatch();

  const isChangingLicenseStatus = useAppSelector((state) => state.class.isChangingLicenseStatus);

  const contract = contracts ? contracts.find((c) => c.id == student.contractId) : null;
  // Licenses known to be available
  const licensesLeft: number = contract
    ? contract.licensesIssued - (contract.consumed || 0) - (contract.reserved || 0)
    : 0;
  const otherContract = student.contractId && (!contracts || contracts.every((c) => c.id != student.contractId));

  const getLicenseText = (status: LicenseStatus | null) => {
    switch (status) {
      case LicenseStatus.consumed:
        return LicenseStatusText.consumed;
      case LicenseStatus.reserved:
        return LicenseStatusText.reserved;
      case LicenseStatus.unreserved:
        if (licensesLeft > 0) {
          return LicenseStatusText.unreserved;
        } else {
          return LicenseStatusText.unavailable;
        }
      case null:
        return LicenseStatusText.unavailable;
      default:
        return LicenseStatusText.unavailable;
    }
  };

  const licenseStatus: LicenseStatus | null = student.licenseStatus ? LicenseStatus[student.licenseStatus] : null;

  const checkboxHandler = (): void => {
    if (student.id && student.contractId)
      dispatch(
        updateLicense({
          classId: classId,
          studentId: student.id,
          contractId: student.contractId,
          licenseStatus:
            student.licenseStatus === LicenseStatus.reserved ? LicenseStatus.unreserved : LicenseStatus.reserved,
        }),
      );
  };

  const id = 'license-check-' + String(classId) + '-' + String(student.id);

  const tip = !clickable
    ? hoverOverNotClickable
    : !licenseStatus
    ? hoverOverNoContract
    : otherContract
    ? hoverOverOtherContract
    : licensesLeft === 0 && licenseStatus === LicenseStatus.unreserved
    ? hoverOverNoAvailable
    : licenseStatus === LicenseStatus.reserved
    ? hoverOverReserved
    : licenseStatus === LicenseStatus.unreserved
    ? hoverOverUnreserved
    : null;

  return (
    <>
      <Label id={id}>
        {clickable && (
          <input
            type="checkbox"
            checked={[LicenseStatus.consumed, LicenseStatus.reserved].includes(licenseStatus)}
            disabled={
              otherContract ||
              !student ||
              !(licenseStatus == LicenseStatus.reserved || licenseStatus == LicenseStatus.unreserved) ||
              isChangingLicenseStatus ||
              (licensesLeft === 0 && licenseStatus === LicenseStatus.unreserved)
            }
            onChange={checkboxHandler}
          />
        )}
        {`${getLicenseText(licenseStatus)}`}
        {debugPrint && ' (' + (student && student.contractId ? student.contractId.toString() : 'none') + ')'}
      </Label>
      {tip != null && (
        <Tooltip anchorSelect={'#' + id} delayShow={250}>
          {tip}
        </Tooltip>
      )}
    </>
  );
};

const Label = styled.label({
  ...textStyle('nav'),
});
