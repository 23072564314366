import React, { useState } from 'react';
import { AuthContainer } from '../components/authContainer';
import { Button } from '../components/button';
import { Link, Navigate } from 'react-router-dom';
import { omit } from 'lodash';
import styled from '@emotion/styled';
import { Alert } from '../components/Alert';
import { TextField } from '../components/textField';
import { AuthOverlay } from '../components/authOverlay';
import { useAppSelector, useAppDispatch } from '../redux';

import { signup as signupAction } from '../redux/actions/user';
import { Text } from '../components/text';
import { UserAccountProfile } from '../../types/routes/user';
import { RadioButtonGroup } from '../components/RadioButtonOptions';

interface SignupInfo {
  name: string | null;
  firstName: string;
  middleName: string | null;
  lastName: string;
  profileRole: string | null;
  school: string;
  email: string;
  password: string;
  confirmPassword: string;
}

interface ApiSignupInfo {
  name: string | null;
  firstName: string;
  middleName: string | null;
  lastName: string;
  emails: string[];
  password: string;
  confirmPassword: string;
  profile: UserAccountProfile;
}

export const Signup: React.FC = () => {
  const [signupInfo, setSignupInfo] = useState<SignupInfo>({
    name: null,
    firstName: '',
    middleName: '',
    lastName: '',
    profileRole: null,
    school: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState<string | undefined>();
  const fetchedUser = useAppSelector((state) => state.user.fetchedUser);
  const fetchingUser = useAppSelector((state) => state.user.fetchingUser);
  const authError = useAppSelector((state) => state.user.authError);
  const authStyle = useAppSelector((state) => state.user.authStyle);
  const dispatch = useAppDispatch();
  const signup = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(undefined);
    /*
     * Allow null-able fields to be empty
     * See https://www.notion.so/prismsvr/Teacher-signup-improvements-43ae18e12af14a99bccc7f40b3c71ff5
     */
    const ignoreValidation = ['name', 'middleName', 'school'];
    for (const [key, value] of Object.entries(signupInfo))
      if (!value && !ignoreValidation.includes(key)) return setError('Please fill out all required fields. *');
    if (signupInfo.password !== signupInfo.confirmPassword) return setError('Passwords do not match.');
    const apiSignupInfo: ApiSignupInfo = {
      ...omit(signupInfo, 'email'),
      emails: [signupInfo.email],
      // profileRole is non-null to the above validation check
      profile: { school: signupInfo.school, profileRole: signupInfo.profileRole ?? undefined },
    };
    dispatch(signupAction(apiSignupInfo));
  };
  const onSignupInfoChange = (attr: keyof SignupInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setSignupInfo({ ...signupInfo, [attr]: event.target.value });
  };
  if (fetchingUser) return <AuthOverlay />;
  return (
    <AuthContainer title="Create a new account to access the Prisms Dashboard!">
      <StyledForm data-cy="signup-form" id="signup" onSubmit={signup}>
        {authError && <Alert severity={authStyle}>{authError}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
        <StyledLabel variant="p">First Name *</StyledLabel>
        <StyledInput
          data-cy="signup-input-first-name"
          placeholder="first name"
          type="text"
          value={signupInfo.firstName}
          onChange={onSignupInfoChange('firstName')}
        />
        <StyledLabel variant="p">Last Name *</StyledLabel>
        <StyledInput
          data-cy="signup-input-last-name"
          placeholder="last name"
          type="text"
          value={signupInfo.lastName}
          onChange={onSignupInfoChange('lastName')}
        />
        <RadioButtonGroup
          title="Role *"
          options={['Teacher', 'Tutor', 'Parent', 'District Administrator', 'School Administrator']}
          value={signupInfo.profileRole}
          onChange={onSignupInfoChange('profileRole')}
          other
        ></RadioButtonGroup>
        <StyledLabel variant="p">School or Organization (if applicable)</StyledLabel>
        <StyledInput
          data-cy="signup-input-school"
          placeholder="School"
          type="text"
          value={signupInfo.school}
          onChange={onSignupInfoChange('school')}
        />

        {/* with mandatory free-text box */}
        <StyledLabel variant="p">Email Address *</StyledLabel>
        <StyledInput
          data-cy="signup-input-email"
          placeholder="E-mail address"
          type="email"
          value={signupInfo.email}
          onChange={onSignupInfoChange('email')}
        />
        <StyledLabel variant="p">Password *</StyledLabel>
        <StyledInput
          data-cy="signup-input-password"
          placeholder="Password"
          type="password"
          value={signupInfo.password}
          onChange={onSignupInfoChange('password')}
        />
        <StyledLabel variant="p">Confirm Password *</StyledLabel>
        <StyledInput
          data-cy="signup-input-password-confirm"
          placeholder="Confirm password"
          type="password"
          value={signupInfo.confirmPassword}
          onChange={onSignupInfoChange('confirmPassword')}
        />
        <StyledLink to="/">
          <Text variant="nav">Cancel & Return to login</Text>
        </StyledLink>
        <StyledButton data-cy="signup-form-button" type="submit">
          Create my account
        </StyledButton>
        {fetchedUser && <Navigate to="/" replace />}
      </StyledForm>
    </AuthContainer>
  );
};

const StyledForm = styled.form({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const StyledLabel = styled(Text)({
  marginBottom: '.5rem',
});

const StyledInput = styled(TextField)({
  marginBottom: '2rem',
});

const StyledButton = styled(Button)({
  width: '100%',
});

// Accessibility:  Links visually distinguishable from non-links
const StyledLink = styled(Link)({
  margin: '0 0 1rem auto',
  color: 'grey',
});
