// @ts-strict-ignore
import { createReducer } from '@reduxjs/toolkit';
import { ModuleEventWithUserClass } from '../../../types/models';
import { getEvents, eventSelector, getDefinitions, getVersionDefinitions, getLibrary } from '../actions/module';
import { VersionDefinition, TaskDefinition } from '../../../types/routes/module';

export interface ModuleLibrary {
  [id: string]: {
    id: string;
    name: string;
    children: ModuleLibrary;
    modules: string[];
  };
}

export interface State {
  events: { [ccmPath: string]: { [userId: number]: ModuleEventWithUserClass[] } };
  maxEventId: { [ccmPath: string]: number };
  eventVersions: string[];
  fetchingModuleEvents: boolean;
  fetchingClassroomEvents: boolean;

  definitions: VersionDefinition;
  fetchingDefinitions: boolean;
  versionDefinitions: {
    [version: string]: VersionDefinition;
  };
  fetchingVersionDefinitions: boolean;
  library: ModuleLibrary;
  fetchingLibrary: boolean;

  tasks: TaskDefinition[];
  tasksModuleId: string | null;
  unableToFetchEventsCount: number;
  unableToFetchEvents: boolean;
}

const initialState: State = {
  events: {},
  maxEventId: {},
  eventVersions: [],
  fetchingModuleEvents: false,
  fetchingClassroomEvents: false,
  definitions: { modules: [], features: [] },
  fetchingDefinitions: false,
  versionDefinitions: {},
  fetchingVersionDefinitions: false,
  library: {},
  fetchingLibrary: false,
  tasks: [],
  tasksModuleId: null,
  unableToFetchEvents: false,
  unableToFetchEventsCount: 0,
};

export default createReducer<State>(initialState, (builder) =>
  builder
    .addCase(getEvents.fulfilled, (state, action) => {
      const debugPrint = false;
      state.fetchingModuleEvents = false;
      const { events, classroomId, classId, moduleId, after } = action.payload;
      const ccmPath = eventSelector({ classroomId, classId, moduleId });
      const oldEvents: { [userId: number]: ModuleEventWithUserClass[] } = { ...state.events[ccmPath] } || {};
      const oldMaxEventId = state.maxEventId[ccmPath] || 0;
      if (after) {
        if (debugPrint && events.length > 0) {
          console.log(`*** got ${events.length} events, after = ${after}`);
        }
        if (after < oldMaxEventId) {
          console.warn(`Overlapping log events: ${after}, ${oldMaxEventId}`);
        } else if (after > oldMaxEventId) {
          console.warn(`Gap in log events: ${after}, ${oldMaxEventId}`);
        }
      } else {
        if (debugPrint) console.log(`*** got ${events.length} events, no after`);
      }
      let duplicateEventCount = 0;
      for (const event of events) {
        if (event.id <= oldMaxEventId) {
          duplicateEventCount += 1;
        } else {
          if (event.userId in oldEvents) oldEvents[event.userId].push(event);
          else oldEvents[event.userId] = [event];
          if (!state.eventVersions.includes(event.version))
            state.eventVersions = [...state.eventVersions, event.version];
        }
      }
      if (events.length > 0) state.events[ccmPath] = oldEvents;
      state.unableToFetchEvents = false;
      state.unableToFetchEventsCount = 0;
      /*
       *  In dev mode, there will be some duplicate messages
       *  due to double-calls induced by <React.StrictMode>
       *  See file index.tsx.
       */
      if (duplicateEventCount > 0 && process.env.NODE_ENV != 'development')
        console.warn(`Duplicate log events:  ${duplicateEventCount} out of ${events.length}`);

      // Don't assume anything about the ordering of the log events.
      if (events.length > 0)
        state.maxEventId[ccmPath] = events.reduce((sum, event) => Math.max(sum, event.id), oldMaxEventId);
    })
    .addCase(getEvents.pending, (state) => {
      state.fetchingModuleEvents = true;
    })
    .addCase(getEvents.rejected, (state) => {
      // @TODO error handling - notify user
      state.fetchingModuleEvents = false;

      state.unableToFetchEventsCount += 1;
      // after 20 seconds of no activity responses received
      // POLLING is every 5 seconds per StatsWrapperClassroom.tsx
      if (state.unableToFetchEventsCount >= 4) {
        state.unableToFetchEvents = true;
      }
    })
    .addCase(getDefinitions.pending, (state) => {
      state.fetchingDefinitions = true;
    })
    .addCase(getDefinitions.fulfilled, (state, action) => {
      state.definitions = action.payload;
      state.fetchingDefinitions = false;
    })
    .addCase(getVersionDefinitions.pending, (state) => {
      state.fetchingVersionDefinitions = true;
    })
    .addCase(getVersionDefinitions.fulfilled, (state, action) => {
      state.versionDefinitions = { ...state.versionDefinitions, [action.payload.version]: action.payload };
      state.fetchingVersionDefinitions = false;
    })
    .addCase(getVersionDefinitions.rejected, (state) => {
      state.fetchingVersionDefinitions = false;
    })
    .addCase(getLibrary.pending, (state) => {
      state.fetchingLibrary = true;
    })
    .addCase(getLibrary.fulfilled, (state, action) => {
      const libraryEndpointSubjectList = action.payload.subjects;
      const mapSubjectsById = libraryEndpointSubjectList.reduce((acc, subject) => {
        return { ...acc, [subject.id]: subject };
      }, {});
      const hardCodedRootList = ['misc', 'math', 'science'];
      function rollup(childIdList: string[]) {
        const returnTree: ModuleLibrary = {};
        childIdList.forEach((child) => {
          const node = mapSubjectsById[child];
          returnTree[node.id] = { ...node, children: rollup(node.children) };
        });
        return returnTree;
      }
      state.library = rollup(hardCodedRootList);
      state.fetchingLibrary = false;
    }),
);
