import React, { useEffect } from 'react';
import { map } from 'lodash';
import styled from '@emotion/styled';

import { PageContainer } from '../components/pageContainer';
import { Header } from '../components/header';
import { useAppSelector, useAppDispatch } from '../redux';
import { charcoal05, primary } from '../utils/colors';
import { Alert } from '../components/Alert';
import { LeftNav } from '../components/leftNav';
import Goggles from '../assets/icons/goggles.svg';
import { Text } from '../components/text';
import { ActionBar, AccessKey } from '../components/styles';
import { userKeys as userKeysAction } from '../redux/actions/user';
import { Spinner } from '../components/Spinner';
import { classDisplayName } from '../utils/class';

export const DeviceAccess: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(userKeysAction());
  }, []);
  const userKeys = useAppSelector((state) => state.user.userKeys);
  const loginName = useAppSelector((state) => state.user.email);
  const fetchingUserKeys = useAppSelector((state) => state.user.fetchingUserKeys);

  const teacherClass = userKeys.filter((c) => c.role == 'teacher');
  const studentClass = userKeys.filter((c) => c.role == 'student');

  return (
    <PageContainer header={<Header />} leftNav={<LeftNav />}>
      <ActionBar>
        <Text variant="lg2" color={primary} data-cy="deviceAccess-header-name">
          <StyledGoggles />
          Headset Login
        </Text>
      </ActionBar>
      {fetchingUserKeys ? (
        <Spinner label="Getting access keys ..." center />
      ) : userKeys.length > 0 ? (
        <>
          <Instruction variant="p" bold>
            You can use the Username and Password below to log into the Prisms app on your headset. This will allow you
            to save your progress. Note: this requires your headset be connected to WiFi.
          </Instruction>
          <StyledText variant="md">Username:</StyledText>
          <Credential key={loginName}>
            <AccessKey color={primary} variant="nav">
              {' '}
              {loginName}
            </AccessKey>
          </Credential>
          {teacherClass.length > 0 && (
            <>
              {studentClass.length > 0 && <StyledText variant="md">Teacher Code:</StyledText>}
              <Credential key={teacherClass[0].id}>
                <AccessKey color={primary} variant="nav">
                  {' '}
                  {teacherClass[0].key}
                </AccessKey>
              </Credential>
            </>
          )}
          {studentClass.length > 0 && (
            <>
              {teacherClass.length > 0 && <StyledText variant="md">Student Codes:</StyledText>}
              {map(studentClass, (c) => (
                <Credential key={c.id}>
                  <AccessKey color={primary} variant="nav">
                    {' '}
                    {c.key}
                  </AccessKey>
                  <Text variant="p">{classDisplayName(c)}</Text>
                  <Text variant="p">{c.section || ''}</Text>
                </Credential>
              ))}{' '}
            </>
          )}
        </>
      ) : (
        <Alert>
          <div>
            <AlertText variant="p">
              To generate a login name and code for accessing the Virtual Reality Application, you must first import or
              create a class.
            </AlertText>
            <AlertText variant="p">
              Login names and passwords will be generated automatically once you&apos;ve created or imported a class.
            </AlertText>
            <AlertText variant="p">Click on the Prisms logo in the top left corner to get started.</AlertText>
          </div>
        </Alert>
      )}
    </PageContainer>
  );
};

const StyledGoggles = styled(Goggles)({
  width: '40px',
  display: 'inline',
  marginRight: '0.5rem',
  verticalAlign: '-0.5rem',
});

const Instruction = styled(Text)({
  marginLeft: '2rem',
  marginTop: '.75rem',
  marginBottom: '.75rem',
});

const Credential = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: `1px solid ${charcoal05}`,
  borderRadius: 3,
  marginBottom: '1rem',
  padding: '2rem',
});

const StyledText = styled(Text)({
  marginLeft: '2rem',
});

const AlertText = styled(Text)({
  padding: '0.5rem 0.5rem 0.5rem',
});
